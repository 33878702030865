/**
 * พิมพ์สัญญาขายรถ เงินสด
 * **/

import React, { Component } from 'react'
import axios from 'axios'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Loading from '../Loading'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { DateUtil } from '../../utils/dateUtil'
import { APIURL } from '../../config/config'
import ThaiBaht from 'thai-baht-text';
import NumberFormat from 'react-number-format'
import { AlertSuccess } from '../Alert/Alert'
import { NumberUtil } from '../../utils/number-util'
import DatePickerIcon from "../DatePickerIcon";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

const port = Number(window.env.PORT);

class CarSalesContract extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contract_id: null,
      contract_no: '',
      write_at: '',
      // date
      startDateContract: new Date(),
      isOpenDateContract: false,
      contract_date_thai: null,
      contract_date: null,
      // end date
      seller_name: '', // ผู้ขาย
      car_name: '',
      car_license_plate: '',
      car_num_engines: '',
      car_number: '', // เลขตัวรถ
      car_year_name: '', // ปี
      car_color_name: '', // สี
      // ขายให้แก่
      buyer_name: '', // สี
      buyer_age: '',
      buyer_address: '',
      buyer_mobile: '',
      card_type: 'บัตรประชาชน',
      card_code: '',
      card_out_at: '',
      real_sell_price: 0,
      cash_pledge: 0,
      // date
      startPaidDueDate: new Date(),
      isOpenPaidDueDate: false,
      paid_due_date_thai: null,
      paid_due_date: null,
      // end date
      isExist: false,
      car_id: '',
      customer_id: '',
      car_sell_id: '',
      province_name : '',
      note : '',
      is_vat: 0,
      vat: 0,
      vat_amount: 0,
      total_amount: 0,
      company_setting: null,
      is_cal_vat: 0
    }

    this.handleDateContract = this.handleDateContract.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarSellById = this.loadCarSellById.bind(this);
    this.loadCarContract = this.loadCarContract.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this);
    this.calOutstanding = this.calOutstanding.bind(this);
    this.handlePaidDueDate = this.handlePaidDueDate.bind(this);
    this.saveCarContract = this.saveCarContract.bind(this);
    this.printCarContract = this.printCarContract.bind(this);
  }

  async componentDidMount () {
    this.mounted = true;
    const queryString = require('query-string');
    let car_sell = queryString.parse(this.props.location.search);
    let car_sell_id = car_sell.car_sell_id;

    await this.loadCompanySetting();
    await this.loadCarContract(car_sell_id);
    await this.loadCarSellById(car_sell_id);

  }

  componentWillUnmount() {
    this.mounted = false
    // window.removeEventListener('resize', this.updateWindowDimensions);
  }

  async loadCompanySetting () {
    await axios.get(APIURL + '/company_setting/1')
      .then(res => {
        let data = res.data
        let company_setting = data.company_setting
        if (company_setting !== null) {
          const is_vat = company_setting.is_vat
          const vat = is_vat === 1 ? company_setting.vat : 0
          // console.log(is_vat, vat)
          this.setState({
            company_setting: company_setting,
            seller_name: company_setting.company_name,
            vat: vat,
            is_vat: is_vat,
          })
        }
      })
  }

  //โหลดข้อมูลหลังจากบันทึกสัญญาแล้ว
  async loadCarContract (car_sell_id) {
    await axios.get(APIURL + '/contract/car_sell/' + car_sell_id)
      .then(res => {
        let data = res.data
        if(data.length === 1){
          let contract = data[0];
          // let contract_id = contract.contract_id
          // contract_id: contract_id
          let contract_date =  moment(contract.contract_date).format('YYYY-MM-DD')
          let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

          let paid_due_date =  moment(contract.paid_due_date).format('YYYY-MM-DD')
          let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

          let province_name = contract.car_license_plate_new !== '' &&  contract.car_license_plate_new !==  contract.car_license_plate_old ? contract.province_name_new : contract.province_name_old

          let is_cal_vat = contract.vat_amount === 0 ? 1 : 0

          this.setState({
            contract_date_thai: contract_date_thai,
            paid_due_date_thai: paid_due_date_thai,

            contract_id : contract.contract_id,
            contract_no : contract.contract_no,
            contract_date : contract.contract_date,
            contract_type : contract.contract_type,
            buyer_address : contract.buyer_address,
            buyer_age : contract.buyer_age,
            buyer_mobile : contract.buyer_mobile,
            buyer_name : contract.buyer_name,
            car_color_name : contract.car_color_name,
            car_id : contract.car_id,
            car_license_plate : contract.car_license_plate,
            car_name : contract.car_name,
            car_num_engines : contract.car_num_engines,
            car_number : contract.car_number,
            car_sell_id : contract.car_sell_id,
            car_year_name : contract.car_year_name,
            card_code : contract.card_code,
            card_out_at : contract.card_out_at,
            card_type : contract.card_type,
            cash_pledge : contract.cash_pledge,
            customer_id : contract.customer_id,
            paid_due_date : contract.paid_due_date,
            //
            real_sell_price : contract.real_sell_price,
            vat : contract.vat,
            vat_amount: contract.vat_amount,
            total_amount: contract.total_amount,
            //
            seller_name : contract.seller_name,
            write_at : contract.write_at,
            note : contract.note,
            province_name : province_name,
            is_cal_vat: is_cal_vat
          })
        }
      })
  }


  async loadCarSellById (car_sell_id) {
    if (this.state.contract_id !== null) {
      // มีรายการสัญญาแล้ว ไม่ต้องโหลดข้อมูล
      return
    }

    const { is_vat, vat } = this.state

    //โหลดข้อมูลตั้งต้น ตอนยังไม่กดบันทึกสัญญา
    await axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if(this.mounted) {
          let car_sell = res.data[0]
          // let car_sell_date = car_sell.car_sell_date
          // let date = new Date(car_sell_date)

          let car_name = car_sell.car_name
          let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
          let car_num_engines = car_sell.car_num_engines // เลขเครื่องยนต์
          let car_number = car_sell.car_number // เลขตัวรถ
          let car_year_name = car_sell.car_year_name // ปี
          let car_color_name = car_sell.car_color_name // สี

          let customer_lastname = car_sell.customer_lastname
          let buyer_name = car_sell.customer_name +' ' + customer_lastname // ผู้ซื้อ
          let buyer_age = car_sell.customer_age // ผู้ซื้อ
          let buyer_address = car_sell.customer_address // ผู้ซื้อ
          let buyer_mobile = car_sell.customer_mobile // ผู้ซื้อ
          let card_code = car_sell.customer_card_id // ผู้ซื้อ

          let real_sell_price = car_sell.real_sell_price
          let car_id = car_sell.car_id
          let customer_id = car_sell.customer_id

          let vat_amount = 0
          let is_cal_vat = 0
          // console.log(car_sell.car_sell_type_id , typeof car_sell.car_sell_type_id)


          if (port === 9038 || port === 9039) {
              // 1.สด 2.ผ่อน
              if (car_sell.car_sell_type_id === 1) {
                vat_amount = 0
                is_cal_vat = 1
              }else{
                if (is_vat === 1) {
                  let vat_amount_cal = real_sell_price * (vat/100);
                  vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
                }
              }
          } else {
            if (is_vat === 1) {
              let vat_amount_cal = real_sell_price * (vat/100);
              vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
            }
          }

          // console.log('port=', port)
          // console.log('is_vat=', is_vat)
          // console.log('vat_amount=', vat_amount)

          let total_amount = Number(real_sell_price) + Number(vat_amount)

          let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

          this.setState({
            car_name,
            car_license_plate,
            car_num_engines,
            car_number,
            car_year_name,
            car_color_name,
            buyer_name,
            buyer_age,
            buyer_address,
            buyer_mobile,
            card_code,
            real_sell_price,
            car_id,
            customer_id,
            province_name,
            car_sell_id,
            vat_amount,
            total_amount,
            is_cal_vat: is_cal_vat
          }, () => {
            this.loadDataCarSellPay(car_sell, car_id, customer_id)
          })
        }
      }).catch(error => {
        console.log ('error:', error)
      })
  }

  handleDateContract (date) {
    let contract_date =  moment(date).format('YYYY-MM-DD')
    let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

    this.setState({
      startDateContract: date,
      isOpenDateContract: false,
      contract_date: contract_date,
      contract_date_thai: contract_date_thai
    })
  }

  handlePaidDueDate (date) {
    let paid_due_date =  moment(date).format('YYYY-MM-DD')
    let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

    this.setState({
      startPaidDueDate: date,
      isOpenPaidDueDate: false,
      paid_due_date: paid_due_date,
      paid_due_date_thai: paid_due_date_thai
    })
  }

  handleOnChange(e) {
    let name = e.target.name;
    let val = e.target.value;

    this.setState({
      [name]: val
    })
  }

  calOutstanding() {
    const { is_vat, vat } = this.state

    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let is_cal_vat = this.state.is_cal_vat

    let vat_amount = Number(this.state.vat_amount)

    if (vat_amount !== 0) {
        if (is_vat === 1) {
          let vat_amount_cal = real_sell_price * (vat / 100);
          vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
        }
    }else{
      if (is_cal_vat === 0) {
        let vat_amount_cal = real_sell_price * (vat / 100);
        vat_amount = parseFloat(String(vat_amount_cal)).toFixed(0);
      }
    }

    let total_amount = Number(real_sell_price) + Number(vat_amount)
    let outstanding = Number(total_amount) - cash_pledge

    this.setState({
      outstanding: outstanding,
      vat_amount,
      total_amount
    })
  }


  loadDataCarSellPay (car_sell, car_id, customer_id) {

    if (car_id === '' || customer_id === '') {
      return
    }

    axios.get(APIURL + '/car_sell_pay/buy/' +car_id + '/' + customer_id)
      .then(res => {
        let car_sell_pay_list = res.data
        let sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)

        let real_sell_price = this.state.real_sell_price
        let outstanding = Number(real_sell_price) - sum_pay

        this.setState({
          cash_pledge: sum_pay,
          outstanding: outstanding
        })

      }).catch(error => {
      console.log(error)
    })
  }

  saveCarContract(action) {

    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines
    let car_number = this.state.car_number
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at
    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai

    let note = this.state.note

    if (contract_no === '') {
      AlertSuccess('กรุณากรอกเลขสัญญา');
      return
    }

    if(write_at === '') {
      AlertSuccess('กรุณากรอกเขียนที่');
      return
    }

    if (contract_date_thai === null) {
      AlertSuccess('กรุณาเลือกวันที่ทำสัญญา');
      return
    }

    if (paid_due_date_thai === null) {
      AlertSuccess('กรุณาเลือกวันที่ครบกำหนดชำระ');
      return
    }

    // vat , vat_amount, total_amount
    let vat = this.state.vat
    let vat_amount = this.state.vat_amount
    let total_amount = this.state.total_amount

    if (vat_amount === '0') {
      vat = 0
    }

    let data = {
      contract_no : contract_no,
      write_at : write_at,
      contract_date : contract_date,
      seller_name : seller_name,
      car_name : car_name,
      car_license_plate : car_license_plate,
      car_num_engines : car_num_engines,
      car_number : car_number,
      car_year_name : car_year_name,
      car_color_name : car_color_name,
      buyer_name : buyer_name,
      buyer_age : buyer_age,
      buyer_address : buyer_address,
      buyer_mobile : buyer_mobile,
      card_type : card_type,
      card_code : card_code,
      card_out_at : card_out_at,
      real_sell_price : real_sell_price,
      cash_pledge : cash_pledge,
      paid_due_date : paid_due_date,
      car_id : car_id,
      customer_id : customer_id,
      car_sell_id : car_sell_id,
      contract_type : contract_type,
      note : note,
      vat,
      vat_amount,
      total_amount,
    }

    this.setState({
      isLoading: true,
    }, () => {
      if (action === 'add') {
        let url = APIURL + '/contract';
        axios.post( url, data)
          .then(res => {
            if (res.status === 200) {
              let resData = res.data
              let contract_id = resData.contract_id;
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
                contract_id: contract_id
                // isExist: true,
              })
            }
          }).catch(error => {
          console.log('error:', error)
        });
      } else if (action === 'edit') {
        let contract_id = this.state.contract_id
        let editData = {...data, contract_id: contract_id }
        let url = APIURL + '/contract';
        axios.put( url, editData)
          .then(res => {
            if (res.data === 'success') {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
              })
            }
          }).catch(error => {
          console.log('error:', error)
        });
      }

    });

  }

  printCarContract() {
    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines
    let car_number = this.state.car_number
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at
    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai
    // let outstanding =  real_sell_price - cash_pledge
    let outstanding =  this.state.outstanding

    let note = this.state.note
    let province_name = this.state.province_name
    let vat = this.state.vat === 0 ? (this.state.company_setting !== null ? this.state.company_setting.vat : '7') : this.state.vat
    let vat_amount = Number(this.state.vat_amount)
    let total_amount = this.state.total_amount

    let row_vat_amount = {}
    if (vat_amount !== 0) {
      row_vat_amount = {
        columns: [
          { width: '30%',
            text: ' ภาษีมูลค่าเพิ่ม '+String(vat)+'%', style: 'normal', marginTop: 0
          },
          { width: '20%', style: 'normalCenter', marginTop: 0,
            text: NumberUtil.addCommas(parseInt(vat_amount, 10)),
          },
          { width: '5%', style: 'normal',marginTop: 0,
            text: 'บาท',
          },
          { width: '45%', style: 'normal', marginTop: 0,
            text: '('+ThaiBaht(vat_amount)+')',
          },
        ],
        columnGap: 0,
        lineHeight:1
      }
    }

    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 20, 35, 0],
      content: [
        {
          columns: [
            { width: '100%', style: 'normal',
              text: '',
            },
          ],
          columnGap: 0,
          lineHeight:1.5
        },
        {
          table: {
            widths: [ '30%', '40%', '30%' ],
            body: [
              [
                {text: '', style: 'normal'},
                {
                  text: 'สัญญาขายรถยนต์',
                  style: 'bodyhead'
                },
                {text: '', style: 'normal'},
              ],
              [
                {text: 'No.'+contract_no, style: 'normal'},
                {text: 'เขียนที่ ' + write_at, style: 'normalRight', colSpan: 2},
                ''
              ],
              [
                {text: 'วันที่ ' + contract_date_thai, style: 'normalRight', colSpan: 3, margin: [0,0,0,5]},
                '',
                ''
              ],
              [
                {text: '', style: 'normalRight', colSpan: 3},
                '',
                ''
              ],
            ],
          },
          layout: 'noBorders',
        },
        {
          columns: [
            { width: '5%',
              text: ' ', style: 'normal',
            },
            { width: '95%', style: 'normal',
              text: 'ข้าพเจ้า____'+ seller_name + '____ตกลงขายรถ____'+ car_name,
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            { width: '100%', style: 'normal',
              text: 'หมายเลขทะเบียน____' + car_license_plate + ' ' + province_name + '____หมายเลขเครื่องยนต์____' + car_num_engines ,
            },
          ],
          columnGap: 0,
          lineHeight: 1
        },
        {
          columns: [
            { width: '100%', style: 'normal',
              text: 'หมายเลขตัวรถ____' + car_number+ '____ปี____'+car_year_name+'____สี____'+car_color_name+'____',
            },
          ],
          columnGap: 0,
          lineHeight: 1.5
        },
        {
          columns: [
            { width: '5%',
              text: '', style: 'normal',
            },
            { width: '95%', style: 'normal',
              text: 'ขายให้แก่____'+ buyer_name + '____อายุ____'+ buyer_age + ' ' +' ปี'+ '____บัตร____'+card_type+'____'+'เลขที่____' + card_code,
            },
          ],
          columnGap: 0,
          lineHeight: 1
        },
        {
          columns: [
            { width: '100%', style: 'normal',
              text: 'ออกให้ ณ____' + card_out_at + '____' + 'ที่อยู่____'+buyer_address+ '____เบอร์โทรศัพท์____'+ buyer_mobile+'____',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            /*{ width: '100%', style: 'normal',
              text: 'หมายเลข....' + card_code + '....' +
                ' ออกให้ ณ....' + card_out_at + '....',
            },*/
          ],
          columnGap: 0,
          lineHeight: 1.5
        },
        {
          columns: [
            { width: '30%',
              text: ' เป็นจำนวนเงิน', style: 'normal', marginTop: 8
            },
            { width: '20%', style: 'normalCenter', marginTop: 8,
              text: NumberUtil.addCommas(parseInt(real_sell_price, 10)),
            },
            { width: '5%', style: 'normal',marginTop: 8,
              text: 'บาท',
            },
            { width: '45%', style: 'normal', marginTop: 8,
              text: '('+ThaiBaht(real_sell_price)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        row_vat_amount
        ,
        {
          columns: [
            { width: '30%',
              text: ' รวมจำนวนเงินทั้งสิ้น', style: 'normal', marginTop: 0
            },
            { width: '20%', style: 'normalCenter', marginTop: 0,
              text: NumberUtil.addCommas(parseInt(total_amount, 10)),
            },
            { width: '5%', style: 'normal',marginTop: 0,
              text: 'บาท',
            },
            { width: '45%', style: 'normal', marginTop: 0,
              text: '('+ThaiBaht(total_amount)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            { width: '30%',
              text: ' ข้าพเจ้าได้รับเงินมัดจำไว้เป็นจำนวนเงิน', style: 'normal',
            },
            { width: '20%', style: 'normalCenter',
              text: NumberUtil.addCommas(parseInt(cash_pledge, 10)),
            },
            { width: '5%', style: 'normal',
              text: 'บาท',
            },
            { width: '45%', style: 'normal',
              text: '('+ThaiBaht(cash_pledge)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            { width: '30%',
              text: ' ผู้ซื้อยังคงค้างอีกเป็นจำนวนเงิน', style: 'normal',
            },
            { width: '20%', style: 'normalCenter',
              text: NumberUtil.addCommas(parseInt(outstanding, 10)),
            },
            { width: '5%', style: 'normal',
              text: 'บาท',
            },
            { width: '45%', style: 'normal',
              text: '('+ThaiBaht(outstanding)+')',
            },
          ],
          columnGap: 0,
          lineHeight: 1
        },
        {
          columns: [
            {
              width: '100%',
              text: 'ผู้ซื้อจะนำเงินมาชำระให้ครบจำนวนตามที่ตกลง ภายใน____วันที่ '+ paid_due_date_thai+'____', style: 'normal',
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 4, 0, 0]
        },
        {
          columns: [
            {
              width: '100%',
              text: 'หากผู้ซื้อไม่นำเงินมาชำระให้ตามกำหนด ผู้ซื้อยินยอมให้ริบเงินมัดจำและคืนรถให้ทันทีตามในสภาพเรียบร้อยทุกประการ ถ้าหากเกิดความเสียหายใดๆ ก็ดี ผู้ซื้อยอมชดใช้ให้ทั้งสิ้น ตลอดทั้งคดีแพ่งและคดีอาญา ส่วนผู้ขายเมื่อได้รับเงินครบถ้วนแล้ว ผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อทันที อากรแสตมป์ซื้อ-ขาย ผู้ซื้อเป็นผู้ออก จะได้ติดให้ครบถ้วน เมื่อวันทำการโอน ทะเบียนยานพาหนะ ฯ ผู้ซื้อ และผู้ขายได้อ่านข้อความเข้าใจดีแล้ว และตกลงกันทั้งสองฝ่าย จึงได้ลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยาน',
              style: 'normal',
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 4, 0, 0]
        },
        {
          columns: [
            {
              width: '45%',
              style: 'normal',
              text: 'เบอร์โทรศัพท์____________________________ผู้ซื้อ'
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)____________________________________ผู้ซื้อ'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 14, 0, 0]
        },
        {
          columns: [
            {
              width: '45%',
              style: 'normal',
              text: 'เบอร์โทรศัพท์____________________________ผู้ขาย'
            },
            {
              width: '5%',
              style: 'normal',
              text: ''
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)____________________________________ผู้ขาย'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 14, 0, 0]
        },
        {
          columns: [
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)____________________________________พยาน'
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)____________________________________พยาน'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 14, 0, 15,]
        },
        {
          table: {
            widths: [ '*' ],
            body: [
              [
                {
                  columns: [
                    {
                      width: '5%',
                      style: 'normal',
                      text: ''
                    },
                    {
                      width: '95%',
                      style: 'normal',
                      text: 'ข้าพเจ้าผู้ซื้อ____'+buyer_name+'____ได้รับรถยนต์____' + car_name+'____หมายเลขทะเบียน____'+car_license_plate+' ' + province_name +'____'
                    }
                  ],
                  margin: [8, 14, 8, 0,],
                  border: [false, true, false, false],
                  fillColor: '#cccccc',
                },
              ],
              [
                {
                  columns: [
                    {
                      width: '5%',
                      style: 'normal',
                      text: ''
                    },
                    {
                      width: '95%',
                      style: 'normal',
                      text: 'ไว้ในครอบครอง เมื่อวันที่_________เดือน__________________พ.ศ._________เวลา_________น.'
                    },
                  ],
                  margin: [8, 0, 8, 0,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],
              [
                {
                  columns: [
                    {
                      width: '5%',
                      style: 'normal',
                      text: ''
                    },
                    {
                      width: '95%',
                      style: 'normal',
                      text: 'ในสภาพเรียบร้อยทุกประการหากเกิดการเสียหายใด ๆ ก็ดีหลังจากที่รับรถยนต์ ดังกล่าวมา ข้าพเจ้าขอรับผิดชอบค่าเสียหาย และความผิดตามกฎหมายต่าง ๆ ทั้งสิ้น'
                    },
                  ],
                  margin: [8, 0, 8, 10,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],
              [
                {
                  columns: [
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)__________________________ผู้ขาย (ผู้มอบรถ)'
                    },
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)____________________________ผู้ซื้อ (ผู้รับรถ)'
                    },
                  ],
                  margin: [8, 0, 8, 8,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],
              [
                {
                  columns: [
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)__________________________พยาน'
                    },
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)____________________________พยาน'
                    },
                  ],
                  margin: [8, 0, 8, 8,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],
            ]
          },
          layout: 'noBorders'
        },
        {
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: note === '' || note === null ? 'หมายเหตุ ______________________________________________________________________________________'
              :
                  'หมายเหตุ ' + ' ' + note
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 10, 0, 0,]
        },
        {
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: note === '' || note === null ? '______________________________________________________________________________________________'
                  : ' '
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 10, 0, 0,]
        },
      ],
      styles: {
        bodyhead: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        normal: {
          fontSize: 15,
        },
        normalRight: {
          fontSize: 15,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 15,
          alignment: 'left',
        },
        normalCenter: {
          fontSize: 15,
          alignment: 'center',
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    pdfMake.createPdf(docDefinition).open();

  }

  render() {
    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-12">
          <div className="card">

            <div className="card-header">
              <strong className="text-title">สัญญาขายรถยนต์</strong>
            </div>

            <div className="card-block" style={{paddingLeft: 80, paddingRight: 80, paddingTop: 10}} >

              <div className="row" style={{lineHeight: 2.0}}>

                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td width={'30%'} style={styles.tdContentCenter} >
                        &nbsp;
                      </td>
                      <td style={styles.tdContentCenter}>
                        <strong style={{fontSize: 18}}> สัญญาขายรถยนต์ </strong>
                      </td>
                      <td width={'30%'}>&nbsp;</td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  >
                        <div className="form-inline mt-5 mb-5">
                          No.
                          <input className="form-control ml-4"
                                 style={styles.inputDataNum}
                                 value={this.state.contract_no || ''}
                                 onChange={(e) => {
                                   this.setState({
                                     contract_no: e.target.value
                                   })
                                 }}
                          />
                        </div>
                      </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight} >

                        <div className="form-inline mt-5 mb-3" style={{justifyContent: 'right' }}>
                          เขียนที่ <input className="form-control ml-4"
                                        style={styles.inputDataWriteAt}
                                        value={this.state.write_at}
                                        onChange={(e) => {
                                          this.setState({
                                            write_at: e.target.value
                                          })
                                        }}
                        />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  > &nbsp; </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight} >
                        <div className="mb-4 row" style={{justifyContent: 'right' }}>
                          วันที่ &nbsp;
                          <input readOnly={true}
                                 className="form-control"
                                 style={{width: 152}}
                                 value={this.state.contract_date_thai === null ? '' : this.state.contract_date_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>
                          {
                            this.state.isOpenDateContract && (
                              <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                inline
                              />
                            )
                          }
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline">
                          <span style={{width: 130, textAlign: 'left'}} >ข้าพเจ้า</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="seller_name"
                                 value={this.state.seller_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 130, textAlign: 'left'}} >ตกลงขายรถ</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="car_name"
                                 value={this.state.car_name}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130, textAlign: 'left'}} >หมายเลขเครื่องยนต์</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="car_num_engines"
                                 value={this.state.car_num_engines}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 130}}  >หมายเลขตัวรถ</span>
                          <input className="form-control ml-1"
                                 style={styles.inputLong}
                                 name="car_number"
                                 value={this.state.car_number}
                                 onChange={this.handleOnChange}
                          />


                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130}} >หมายเลขทะเบียน</span>
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="car_license_plate"
                                 value={this.state.car_license_plate}
                                 onChange={this.handleOnChange}
                          />
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="province_name"
                                 disabled
                                 value={this.state.province_name}
                                 //onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 30, textAlign: 'center'}}>ปี</span>&nbsp;&nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-2"
                                 style={styles.inputShort}
                                 name="car_year_name"
                                 value={this.state.car_year_name}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={4}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 30, textAlign: 'center'}}>สี</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-1"
                                 style={styles.inputShort}
                                 name="car_color_name"
                                 value={this.state.car_color_name}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    {/*{ขายให้แก่}*/}
                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 130, textAlign: 'left'}} >ขายให้แก่</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="buyer_name"
                                 value={this.state.buyer_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 30, textAlign: 'center'}} >อายุ</span>
                          <input className="form-control ml-3"
                                 style={styles.inputShort}
                                 name="buyer_age"
                                 value={this.state.buyer_age}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={2}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 50}}  >บัตร</span>
                          <input className="form-control"
                                 style={styles.inputShort }
                                 name="card_type"
                                 value={this.state.card_type}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130, textAlign: 'left'}}> เลขที่ </span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="card_code"
                                 value={this.state.card_code}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 100, textAlign: 'center'}}> ออกให้ ณ เขต</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="card_out_at"
                                 value={this.state.card_out_at}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130, textAlign: 'left'}} >ที่อยู่</span>
                          <input className="form-control ml-2"
                                 style={{width: '45%'}}
                                 name="buyer_address"
                                 value={this.state.buyer_address}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 80, textAlign: 'center'}} >โทรศัพท์</span>
                          <input className="form-control ml-3"
                                 style={styles.inputLong}
                                 name="buyer_mobile"
                                 value={this.state.buyer_mobile}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 250}} >เป็นจำนวนเงิน</span>
                         {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="real_sell_price"
                                 value={this.state.real_sell_price}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.real_sell_price}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({real_sell_price: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-1" >({ThaiBaht(this.state.real_sell_price)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 250}} className="form-inline" >
                            ภาษีมูลค่าเพิ่ม
                            {this.state.vat === 0 ? (this.state.company_setting !== null ? this.state.company_setting.vat : '7') : this.state.vat}%

                            <div style={{width: 100}} className="form-group text-left mt-0 ml-1">

                            <input type="checkbox"
                                   name={'is_cal_vat'}
                                   value={this.state.is_cal_vat}
                                   checked={this.state.is_cal_vat === 1}
                                   onChange={(e)=>{
                                     let value = e.target.value === '0' ? 1 : 0
                                     // let vat_amount = value === 1 ? 0 :
                                     if (value === 1) {
                                       this.setState({
                                         is_cal_vat: value,
                                         vat_amount: 0
                                       })
                                     } else {
                                       this.setState({
                                         is_cal_vat: value,
                                       }, () => this.calOutstanding() )
                                     }
                                   }}
                            />
                            <span> &nbsp;ไม่คิด VAT</span>

                          </div>

                          </span>
                          {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="real_sell_price"
                                 value={this.state.real_sell_price}
                                 onChange={() => {}}
                          />*/}



                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.vat_amount}
                            onValueChange={(values) => {
                              const {value} = values
                              /*console.log(value, typeof value)
                              if (value === '0') {
                                // no vat
                                // this.setState({vat_amount: value}, () => this.calOutstanding())
                              }*/
                              if (this.state.contract_id === null) {
                                this.setState({vat_amount: value}, () => this.calOutstanding())
                              } else {
                                if (value !== '0') {
                                  this.setState({
                                    vat_amount: value,
                                    vat: this.state.company_setting.vat
                                  }, () => this.calOutstanding())
                                } else {
                                  this.setState({
                                    vat_amount: value,
                                    vat: 0
                                  }, () => this.calOutstanding())
                                }
                              }

                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-1" >({ThaiBaht(this.state.vat_amount)})</span>



                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 250}} >รวมจำนวนเงินทั้งสิ้น</span>
                          {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="real_sell_price"
                                 value={this.state.real_sell_price}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.total_amount}
                            onValueChange={(values) => {
                              // const {value} = values;
                              // console.log('total_amount=', value);
                              // this.setState({total_amount: value})
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-1" >({ThaiBaht(this.state.total_amount)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 250}} >ข้าพเจ้าได้รับเงินมัดจำไว้เป็นเงิน</span>

                         {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="cash_pledge"
                                 value={this.state.cash_pledge}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.cash_pledge}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({cash_pledge: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({ThaiBaht(this.state.cash_pledge)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 250}} >ผู้ซื้อยังคงค้างอีกเป็นจำนวนเงิน</span>
                          {/*<input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="outstanding"
                                 value={this.state.outstanding}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.outstanding}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({outstanding: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({ThaiBaht(this.state.outstanding)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                            <span style={{width: 380}} >ผู้ซื้อจะนำเงินมาชำระให้ครบจำนวนตามที่ตกลง ภายในวันที่</span>
                            <input readOnly={true}
                                   className="form-control ml-3"
                                   name="paid_due_date_thai"
                                   style={{width: 160, height: 38}}
                                   value={this.state.paid_due_date_thai === null ? '' : this.state.paid_due_date_thai}
                            />
                            <div style={styles.iconCalendar}>
                              <DatePicker
                                  selected={this.state.startPaidDueDate}
                                  onChange={this.handlePaidDueDate}
                                  dateFormat="yyyy-MM-dd"
                                  customInput={<DatePickerIcon />}
                              />
                            </div>

                            {
                              this.state.isOpenPaidDueDate && (
                                <DatePicker
                                  selected={this.state.startPaidDueDate}
                                  onChange={this.handlePaidDueDate}
                                  dateFormat="yyyy-MM-dd"
                                  inline
                                />
                              )
                            }

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span >
                            หากผู้ซื้อไม่นำเงินมาชำระให้ตามกำหนด ผู้ซื้อยินยอมให้ริบเงินมัดจำและคืนรถให้ทันทีตามในสภาพเรียบร้อยทุกประการ &nbsp;
                            ถ้าหากเกิดความเสียหายใดๆ ก็ดี ผู้ซื้อยอมชดใช้ให้ทั้งสิ้น ตลอดทั้งคดีแพ่ง และคดีอาญา ส่วนผู้ขายเมื่อได้รับเงินครบถ้วนแล้ว
                            ผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อทันที อากรแสตมป์ซื้อ-ขาย ผู้ซื้อเป็นผู้ออก จะได้ติดให้ครบถ้วน เมื่อวันทำการโอนทะเบียนยานพาหนะ ฯ
                            ผู้ซื้อ และผู้ขายได้อ่านข้อความเข้าใจดีแล้ว และตกลงกันทั้งสองฝ่าย จึงได้ลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยาน
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <table style={styles.table}>
                            <tbody>
                              <tr className="mt-3">
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  โทร______________________________ผู้ซื้อ
                                </td>
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  (ลงนาม)________________________________________ผู้ซื้อ
                                </td>
                              </tr>
                              <tr >
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                 <div className="mt-3"> โทร______________________________ผู้ขาย </div>
                                </td>
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  <div className="mt-3"> (ลงนาม)________________________________________ผู้ขาย </div>
                                </td>
                              </tr>
                              <tr>
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  <div className="mt-3"> (ลงนาม)________________________________________พยาน </div>
                                </td>
                                <td style={{...styles.tdContentLeft, width: '50%'}} >
                                  <div className="mt-3"> (ลงนาม)________________________________________พยาน </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={{width: '100%'}} colSpan={3}>
                        <div className="mt-3" style={{border: '1px solid #000', backgroundColor: '#ccc', width: '100%', minHeight: 250}} >
                          <table style={styles.table}>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                     <span style={{width: 150, textAlign: 'left'}} >ข้าพเจ้าผู้ซื้อ</span>
                                     <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           value={this.state.buyer_name}
                                           onChange={(e) => {}}
                                     />
                                    <span style={{width: 150, textAlign: 'center'}} >ได้รับรถยนต์</span>
                                    <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           name="car_name"
                                           value={this.state.car_name}
                                           onChange={(e) => {}}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span style={{width: 150}} >หมายเลขทะเบียน</span>
                                    <input className="form-control ml-2"
                                           style={{width: 140}}
                                           name="car_license_plate"
                                           value={this.state.car_license_plate}
                                           onChange={this.handleOnChange}
                                    />
                                    <input className="form-control ml-2"
                                           style={{width: 140}}
                                           name="province_name"
                                           disabled
                                           value={this.state.province_name}
                                        //onChange={this.handleOnChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span> ไว้ในครอบครอง เมื่อวันที่_________เดือน__________________พ.ศ._________</span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span> เวลา_________น. &nbsp;&nbsp;&nbsp;&nbsp;ในสภาพเรียบร้อยทุกประการหากเกิดการเสียหายใด ๆ ก็ดีหลังจากที่รับรถยนต์ ดังกล่าวมา ข้าพเจ้าขอรับผิดชอบค่าเสียหาย และความผิดตามกฎหมายต่าง ๆ ทั้งสิ้น</span>
                                  </div>
                                </td>
                              </tr>
                              {/*<tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span> ข้าพเจ้าขอรับผิดชอบค่าเสียหาย และความผิดตามกฎหมายต่าง ๆ ทั้งสิ้น</span>
                                  </div>
                                </td>
                              </tr>*/}
                              <tr>
                                <td>
                                  <div className="form-inline mt-0">&nbsp;</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-0">
                                    <table style={styles.table}>
                                      <tbody>
                                          <tr>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________ผู้ขาย (ผู้มอบรถ) </div>
                                            </td>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________ผู้ซื้อ (ผู้รับรถ) </div>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________พยาน </div>
                                            </td>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________พยาน </div>
                                            </td>
                                          </tr>

                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 90, textAlign: 'left'}} > หมายเหตุ </span>
                          <input className="form-control"
                                 style={{width: '80%'}}
                                 name="note"
                                 value={this.state.note}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>

                  </tbody>
                </table>
              </div>

              <div className="col-md-12 text-right">
                <div className="card-block">

                  {
                    this.state.contract_id === null ? (
                      <button type="button"
                              className="btn btn-success btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.saveCarContract('add')}
                      >
                        <i className="icon-check"/>
                        &nbsp;บันทึก
                      </button>
                    ) : <button type="button"
                                className="btn btn-warning btn-md mr-2"
                                style={{width: 120}}
                                onClick={() => this.saveCarContract('edit')}
                    >
                      <i className="icon-pencil"/>
                      &nbsp;แก้ไข
                    </button>
                  }

                  {
                    this.state.contract_id === null ? null : (
                      <button type="button"
                              className="btn btn-primary btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.printCarContract()}
                      >
                        <i className="icon-printer"/>  พิมพ์
                      </button>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}


const styles = {
  table: {
    width: '100%',
    maxWidth: '100%'
  },
  tdContentCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tdContentRight: {
    textAlign: 'right',
    verticalAlign: 'middle',
    justifyContent: 'right',
  },
  tdContentLeft: {
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  tableTh:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tableTdLeft:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  tableTdCenter:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingLeft: '70px',
  },
  tableTdRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
  },
  tablePd:{
    paddingLeft: '50px',
    paddingRight: '50px',
    lineHeight: '2.2',
  },
  amountsText:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  amountsTextNoBorder:{
    maxWidth: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  amountsRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
    fontWeight: 'bold',
  },
  buttonHeight: {
    height: 32,
  },
  inputData:{
    borderRadius: '5px',
    width: '450px',
  },
  inputDataNum:{
    borderRadius: '5px',
    width: '100px'
  },
  inputDataWriteAt:{
    borderRadius: '5px',
    width: '180px'
  },
  inputShort: {
    borderRadius: '5px',
    width: '120px'
  },
  inputLong: {
    borderRadius: '1px',
    width: '220px'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarSalesContract)
