import React, { Component } from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {
  APIURL,
  IMAGE_THUMB_URL,
  IMAGE_NOPHOTO_URL,
  IMAGE_FULL_URL,
  IMAGE_UPLOAD_ERROR,
  PORT
} from '../../../config/config'
import { checkGroupPermission } from '../../../services/grouppermission'
import { AlertSuccess } from '../../Alert/Alert'
import Swal from "sweetalert2";

class CarEditAlbum extends Component {

  constructor (props){
    super(props)

    this.state = {
      user: {},
      front_car: IMAGE_NOPHOTO_URL,
      front_right: IMAGE_NOPHOTO_URL,
      front_left: IMAGE_NOPHOTO_URL,
      side_car_left: IMAGE_NOPHOTO_URL,
      side_car_right: IMAGE_NOPHOTO_URL,
      back_straight: IMAGE_NOPHOTO_URL,
      condition_in_car: IMAGE_NOPHOTO_URL,
      engine_car: IMAGE_NOPHOTO_URL,
      console_car: IMAGE_NOPHOTO_URL,
      plate_car: IMAGE_NOPHOTO_URL,
      other_car: IMAGE_NOPHOTO_URL,
      console: IMAGE_NOPHOTO_URL,
      gear: IMAGE_NOPHOTO_URL,
      audio_equipment: IMAGE_NOPHOTO_URL,
      dial: IMAGE_NOPHOTO_URL,
      catalyticconverter: IMAGE_NOPHOTO_URL,
      yearoftire: IMAGE_NOPHOTO_URL,
      chassis: IMAGE_NOPHOTO_URL,
      other1: IMAGE_NOPHOTO_URL,
      other2: IMAGE_NOPHOTO_URL,
      other3: IMAGE_NOPHOTO_URL,
      plate: IMAGE_NOPHOTO_URL,
      engine_oil: IMAGE_NOPHOTO_URL,
      front_left_tire_number: IMAGE_NOPHOTO_URL,
      front_right_tire_number: IMAGE_NOPHOTO_URL,
      back_left_tire_number: IMAGE_NOPHOTO_URL,
      back_right_tire_number: IMAGE_NOPHOTO_URL,
      car_key: IMAGE_NOPHOTO_URL,
      //
      front_car_full: IMAGE_NOPHOTO_URL,
      front_right_full: IMAGE_NOPHOTO_URL,
      front_left_full: IMAGE_NOPHOTO_URL,
      side_car_left_full: IMAGE_NOPHOTO_URL,
      side_car_right_full: IMAGE_NOPHOTO_URL,
      back_straight_full: IMAGE_NOPHOTO_URL,
      condition_in_car_full: IMAGE_NOPHOTO_URL,
      engine_car_full: IMAGE_NOPHOTO_URL,
      console_car_full: IMAGE_NOPHOTO_URL,
      plate_car_full: IMAGE_NOPHOTO_URL,
      other_car_full: IMAGE_NOPHOTO_URL,
      console_full: IMAGE_NOPHOTO_URL,
      gear_full: IMAGE_NOPHOTO_URL,
      audio_equipment_full: IMAGE_NOPHOTO_URL,
      dial_full: IMAGE_NOPHOTO_URL,
      catalyticconverter_full: IMAGE_NOPHOTO_URL,
      yearoftire_full: IMAGE_NOPHOTO_URL,
      chassis_full: IMAGE_NOPHOTO_URL,
      other1_full: IMAGE_NOPHOTO_URL,
      other2_full: IMAGE_NOPHOTO_URL,
      other3_full: IMAGE_NOPHOTO_URL,
      plate_full: IMAGE_NOPHOTO_URL,
      engine_oil_full: IMAGE_NOPHOTO_URL,
      front_left_tire_number_full: IMAGE_NOPHOTO_URL,
      front_right_tire_number_full: IMAGE_NOPHOTO_URL,
      back_left_tire_number_full: IMAGE_NOPHOTO_URL,
      back_right_tire_number_full: IMAGE_NOPHOTO_URL,
      car_key_full: IMAGE_NOPHOTO_URL,
      //
      front_car2: IMAGE_NOPHOTO_URL,
      front_right2: IMAGE_NOPHOTO_URL,
      front_left2: IMAGE_NOPHOTO_URL,
      back_left2: IMAGE_NOPHOTO_URL,
      back_right2: IMAGE_NOPHOTO_URL,

      side_car_left2: IMAGE_NOPHOTO_URL,
      side_car_right2: IMAGE_NOPHOTO_URL,
      back_straight2: IMAGE_NOPHOTO_URL,
      condition_in_car2: IMAGE_NOPHOTO_URL,
      engine_car2: IMAGE_NOPHOTO_URL,
      console_car2: IMAGE_NOPHOTO_URL,
      plate_car2: IMAGE_NOPHOTO_URL,
      front_right_seat2: IMAGE_NOPHOTO_URL,
      back_right_seat2: IMAGE_NOPHOTO_URL,
      gear2: IMAGE_NOPHOTO_URL,
      audio_equipment2: IMAGE_NOPHOTO_URL,
      dial2: IMAGE_NOPHOTO_URL,
      catalyticconverter2: IMAGE_NOPHOTO_URL,
      yearoftire2: IMAGE_NOPHOTO_URL,
      chassis2: IMAGE_NOPHOTO_URL,
      other_car2: IMAGE_NOPHOTO_URL,
      other_car22: IMAGE_NOPHOTO_URL,
      other_car23: IMAGE_NOPHOTO_URL,
      engine_oil2: IMAGE_NOPHOTO_URL,
      front_left_tire_number2: IMAGE_NOPHOTO_URL,
      front_right_tire_number2: IMAGE_NOPHOTO_URL,
      back_left_tire_number2: IMAGE_NOPHOTO_URL,
      back_right_tire_number2: IMAGE_NOPHOTO_URL,
      car_key2: IMAGE_NOPHOTO_URL,
      //
      front_car_id2: '0',
      front_right_id2: '0',
      front_left_id2: '0',
      back_left_id2: '0',
      back_right_id2: '0',

      side_car_left_id2: '0',
      side_car_right_id2: '0',
      back_straight_id2: '0',
      console_car_id2: '0',
      condition_in_car_id2: '0',
      engine_car_id2: '0',
      plate_car_id2: '0',
      front_right_seat_id2: '0',
      back_right_seat_id2: '0',
      gear_id2: '0',
      audio_equipment_id2: '0',
      dial_id2: '0',
      catalyticconverter_id2: '0',
      yearoftire_id2: '0',
      chassis_id2: '0',
      other_car_id2: '0',
      other_car2_id2: '0',
      other_car3_id2: '0',
      engine_oil_id2: '0',
      front_left_tire_number_id2: 0,
      front_right_tire_number_id2: 0,
      back_left_tire_number_id2: 0,
      back_right_tire_number_id2: 0,
      car_key_id2: 0,
      //
      photos: [],
      front_car_id: 0,
      front_right_id: 0,
      front_left_id: 0,
      side_car_left_id: 0,
      side_car_right_id: 0,
      back_straight_id: 0,
      condition_in_car_id: 0,
      engine_car_id: 0,
      console_car_id: 0,
      plate_car_id: 0,
      other_car_id: 0,
      console_id: 0,
      gear_id: 0,
      audio_equipment_id: 0,
      dial_id: 0,
      catalyticconverter_id: 0,
      yearoftire_id: 0,
      chassis_id: 0,
      other1_id: 0,
      other2_id: 0,
      other3_id: 0,
      plate_id: 0,
      engine_oil_id: 0,
      front_left_tire_number_id: 0,
      front_right_tire_number_id: 0,
      back_left_tire_number_id: 0,
      back_right_tire_number_id: 0,
      car_key_id: 0,
    }

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleUploadFile2 = this.handleUploadFile2.bind(this);
    this.saveCarImage = this.saveCarImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);

  }

  componentWillMount() {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }
  componentDidMount() {
     //var upload_code = this.props.car.car_upload_code
     //this.getCarUploadPhoto(componentDidMountupload_code);
     // console.log("componentDidMount="+this.props.photos)
      this.getCarUploadPhoto(this.props.upload_code)
  }

  getCarUploadPhoto(upload_code){
    // alert(APIURL + '/car_upload/'+upload_code)
    // console.log(upload_code)
    axios.get(APIURL + '/car_upload/all/'+upload_code)
      .then(res => {
        this.setState({photos: res.data},()=>{
          this.setCarAlbum();
        })
      }).catch(error => {
      console.log("error="+error)
      this.setState({photos: []})
    })
  }

  setCarAlbum(){

    // eslint-disable-next-line
    this.state.photos.map(photo => {

      let image_url = '';

      // console.log(photo.car_upload_position)

      if (photo.car_upload_filename.includes('https')) {
        image_url = photo.car_upload_filename
      } else {
        image_url =  IMAGE_FULL_URL+photo.car_upload_filename
      }

      if (photo.car_upload_position === 'front_car') {
        this.setState({
          front_car: image_url,
          front_car_full: image_url,
          front_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_right'){
        this.setState({
           front_right: image_url,
           front_right_full: image_url,
           front_right_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_left'){
        this.setState({
          front_left: image_url,
          front_left_full: image_url,
          front_left_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_left'){
        this.setState({
          side_car_left: image_url,
          side_car_left_full: image_url,
          side_car_left_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_right'){
        this.setState({
          side_car_right: image_url,
          side_car_right_full: image_url,
          side_car_right_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'back_straight'){
        this.setState({
          back_straight: image_url,
          back_straight_full: image_url,
          back_straight_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'condition_in_car'){
        this.setState({
          condition_in_car: image_url,
          condition_in_car_full: image_url,
          condition_in_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'engine_car'){
        this.setState({
          engine_car: image_url,
          engine_car_full: image_url,
          engine_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'console_car'){
        this.setState({
          console_car: image_url,
          console_car_full: image_url,
          console_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'plate_car'){
        this.setState({
          plate_car: image_url,
          plate_car_full: image_url,
          plate_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'other_car'){
        this.setState({
          other_car: image_url,
          other_car_full: image_url,
          other_car_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'console'){
        this.setState({
          console: image_url,
          console_full: image_url,
          console_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'gear'){
        this.setState({
          gear: image_url,
          gear_full: image_url,
          gear_id: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'audio_equipment'){
        this.setState({
          audio_equipment: image_url,
          audio_equipment_full: image_url,
          audio_equipment_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'dial'){
        this.setState({
          dial: image_url,
          dial_full: image_url,
          dial_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'catalyticconverter'){
        this.setState({
          catalyticconverter: image_url,
          catalyticconverter_full: image_url,
          catalyticconverter_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'yearoftire'){
        this.setState({
          yearoftire: image_url,
          yearoftire_full: image_url,
          yearoftire_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'chassis'){
        this.setState({
          chassis: image_url,
          chassis_full: image_url,
          chassis_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'other1'){
        this.setState({
          other1: image_url,
          other1_full: image_url,
          other1_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'other2'){
        this.setState({
          other2: image_url,
          other2_full: image_url,
          other2_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'other3'){
        this.setState({
          other3: image_url,
          other3_full: image_url,
          other3_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'plate'){
        this.setState({
          plate: image_url,
          plate_full: image_url,
          plate_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'engine_oil'){
        this.setState({
          engine_oil: image_url,
          engine_oil_full: image_url,
          engine_oil_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'front_left_tire_number'){
        this.setState({
          front_left_tire_number: image_url,
          front_left_tire_number_full: image_url,
          front_left_tire_number_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'front_right_tire_number'){
        this.setState({
          front_right_tire_number: image_url,
          front_right_tire_number_full: image_url,
          front_right_tire_number_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'back_left_tire_number'){
        this.setState({
          back_left_tire_number: image_url,
          back_left_tire_number_full: image_url,
          back_left_tire_number_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'back_right_tire_number'){
        this.setState({
          back_right_tire_number: image_url,
          back_right_tire_number_full: image_url,
          back_right_tire_number_id: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'car_key'){
        this.setState({
          car_key: image_url,
          car_key_full: image_url,
          car_key_id: photo.car_upload_id
        })
      }
      //

      /// ภาพก่อนแต่ง ตอนปล่อยรถ
      if (photo.car_upload_position === 'front_car2') {
        this.setState({
          front_car2: image_url,
          front_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_right2'){
        this.setState({
          front_right2: image_url,
          front_right_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_left2'){
        this.setState({
          front_left2: image_url,
          front_left_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'back_left2'){
        this.setState({
          back_left2: image_url,
          back_left_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'back_right2'){
        this.setState({
          back_right2: image_url,
          back_right_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_left2'){
        this.setState({
          side_car_left2: image_url,
          side_car_left_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'side_car_right2'){
        // side_car_right2: IMAGE_THUMB_URL+photo.car_upload_filename,
        this.setState({
          side_car_right2: image_url,
          side_car_right_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'back_straight2'){
        this.setState({
          back_straight2: image_url,
          back_straight_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'condition_in_car2'){
        this.setState({
          condition_in_car2: image_url,
          condition_in_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'engine_car2'){
        this.setState({
          engine_car2: image_url,
          engine_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'console_car2'){
        this.setState({
          console_car2: image_url,
          console_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'plate_car2'){
        this.setState({
          plate_car2: image_url,
          plate_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'front_right_seat2'){
        this.setState({
          front_right_seat2: image_url,
          front_right_seat_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'back_right_seat2'){
        this.setState({
          back_right_seat2: image_url,
          back_right_seat_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'gear2'){
        this.setState({
          gear2: image_url,
          gear_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'audio_equipment2'){
        this.setState({
          audio_equipment2: image_url,
          audio_equipment_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'dial2'){
        this.setState({
          dial2: image_url,
          dial_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'catalyticconverter2'){
        this.setState({
          catalyticconverter2: image_url,
          catalyticconverter_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'yearoftire2'){
        this.setState({
          yearoftire2: image_url,
          yearoftire_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'chassis2'){
        this.setState({
          chassis2: image_url,
          chassis_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'other_car2'){
        this.setState({
          other_car2: image_url,
          other_car_id2: photo.car_upload_id
        })
      }
      else if(photo.car_upload_position === 'other_car22'){
        this.setState({
          other_car22: image_url,
          other_car2_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'other_car23'){
        this.setState({
          other_car23: image_url,
          other_car3_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'engine_oil2'){
        this.setState({
          engine_oil2: image_url,
          engine_oil_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'front_left_tire_number2'){
        this.setState({
          front_left_tire_number2: image_url,
          front_left_tire_number_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'front_right_tire_number2'){
        this.setState({
          front_right_tire_number2: image_url,
          front_right_tire_number_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'back_left_tire_number2'){
        this.setState({
          back_left_tire_number2: image_url,
          back_left_tire_number_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'back_right_tire_number2'){
        this.setState({
          back_right_tire_number2: image_url,
          back_right_tire_number_id2: photo.car_upload_id
        })
      } else if(photo.car_upload_position === 'car_key2'){
        this.setState({
          car_key2: image_url,
          car_key_id2: photo.car_upload_id
        })
      }

    });
  }

  handleUploadFile (event){

    if(checkGroupPermission(this.props.menuID, this.props.grouppermissions).modified === 0 ) {
      AlertSuccess('สิทธิ์ของท่านไม่สามารถแก้ไขภาพรถได้');
      return
    }

    let input_file_name = event.target.name
    let that = this;

    const data = new FormData();

    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('upload_code', this.props.upload_code);
    data.append('user_updated', this.state.user.user);

    // data.append('car_upload_position', input_file_name);
    // alert(this.state.upload_code)

    if( input_file_name === 'front_car'){
      this.refs.img_front_car.src='img/loading.gif'
      this.refs.img_front_car.height=32
      data.append('car_upload_position', 'front_car');
      data.append('car_upload_id', this.state.front_car_id)
    }else if(input_file_name === 'front_right'){
      this.refs.img_front_right.src='img/loading.gif'
      data.append('car_upload_position', 'front_right');
      data.append('car_upload_id', this.state.front_right_id )
    }else if(input_file_name === 'front_left'){
      this.refs.img_front_left.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left');
      data.append('car_upload_id', this.state.front_left_id )
    }else if(input_file_name === 'side_car_left'){
      this.refs.img_side_car_left.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_left');
      data.append('car_upload_id', this.state.side_car_left_id )
    }else if(input_file_name === 'side_car_right'){
      this.refs.img_side_car_right.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_right');
      data.append('car_upload_id', this.state.side_car_right_id )
    }else if(input_file_name === 'back_straight'){
      this.refs.img_back_straight.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_straight');
      data.append('car_upload_id', this.state.back_straight_id )
    }else if(input_file_name === 'console_car'){
      this.refs.img_console_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'console_car');
      data.append('car_upload_id', this.state.console_car_id )
    }else if(input_file_name === 'condition_in_car'){
      this.refs.img_condition_in_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'condition_in_car');
      data.append('car_upload_id', this.state.condition_in_car_id )
    }else if(input_file_name === 'engine_car'){
      this.refs.img_engine_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_car');
      data.append('car_upload_id', this.state.engine_car_id )
    }else if(input_file_name === 'plate_car'){
      this.refs.img_plate_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate_car');
      data.append('car_upload_id', this.state.plate_car_id )
    }else if(input_file_name === 'other_car'){
      this.refs.img_other_car.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car');
      data.append('car_upload_id', this.state.other_car_id )
      // add เพิ่ม
    } else if (input_file_name === 'console') {
      // 12.คอนโซล
      this.refs.img_console.src = 'img/loading.gif'
      data.append('car_upload_position', 'console')
      data.append('car_upload_id', this.state.console_id )
    } else if (input_file_name === 'gear') {
      // 13.เกียร์
      this.refs.img_gear.src = 'img/loading.gif'
      data.append('car_upload_position', 'gear')
      data.append('car_upload_id', this.state.gear_id )
    } else if (input_file_name === 'audio_equipment') {
      // 14.เครื่องเสียง
      this.refs.img_audio_equipment.src = 'img/loading.gif'
      data.append('car_upload_position', 'audio_equipment')
      data.append('car_upload_id', this.state.audio_equipment_id )
    } else if (input_file_name === 'dial') {
      // 15) หน้าปัด
      this.refs.img_dial.src = 'img/loading.gif'
      data.append('car_upload_position', 'dial')
      data.append('car_upload_id', this.state.dial_id )
    } else if (input_file_name === 'catalyticconverter') {
      // 16) ท่อแคท
      this.refs.img_catalyticconverter.src = 'img/loading.gif'
      data.append('car_upload_position', 'catalyticconverter')
      data.append('car_upload_id', this.state.catalyticconverter_id )
    } else if (input_file_name === 'yearoftire') {
      // 17) ปียางรถ
      this.refs.img_yearoftire.src = 'img/loading.gif'
      data.append('car_upload_position', 'yearoftire')
      data.append('car_upload_id', this.state.yearoftire_id )
    } else if (input_file_name === 'chassis') {
      // 18) คัดซี
      this.refs.img_chassis.src = 'img/loading.gif'
      data.append('car_upload_position', 'chassis')
      data.append('car_upload_id', this.state.chassis_id )
    } else if (input_file_name === 'other1') {
      // 19) อื่นๆ 1
      this.refs.img_other1.src = 'img/loading.gif'
      data.append('car_upload_position', 'other1')
      data.append('car_upload_id', this.state.other1_id )
    } else if (input_file_name === 'other2') {
      // 20) อื่นๆ 2
      this.refs.img_other2.src = 'img/loading.gif'
      data.append('car_upload_position', 'other2')
      data.append('car_upload_id', this.state.other2_id )
    } else if (input_file_name === 'other3') {
      // 21) อื่นๆ 3
      this.refs.img_other3.src = 'img/loading.gif'
      data.append('car_upload_position', 'other3')
      data.append('car_upload_id', this.state.other3_id )
    } else if (input_file_name === 'plate') {
      // plate
      this.refs.img_plate.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate')
      data.append('car_upload_id', this.state.plate_id )
    } else if (input_file_name === 'engine_oil') {
      this.refs.img_engine_oil.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_oil')
      data.append('car_upload_id', this.state.engine_oil_id )
    } else if (input_file_name === 'front_left_tire_number') {
      this.refs.img_front_left_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left_tire_number')
      data.append('car_upload_id', this.state.front_left_tire_number_id )
    } else if (input_file_name === 'front_right_tire_number') {
      this.refs.img_front_right_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_right_tire_number')
      data.append('car_upload_id', this.state.front_right_tire_number_id )
    } else if (input_file_name === 'back_left_tire_number') {
      this.refs.img_back_left_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_left_tire_number')
      data.append('car_upload_id', this.state.back_left_tire_number_id )
    } else if (input_file_name === 'back_right_tire_number') {
      this.refs.img_back_right_tire_number.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_right_tire_number')
      data.append('car_upload_id', this.state.back_right_tire_number_id )
    } else if (input_file_name === 'car_key') {
      this.refs.img_car_key.src = 'img/loading.gif'
      data.append('car_upload_position', 'car_key')
      data.append('car_upload_id', this.state.car_key_id )
    }

    //alert(input_file_name)
    axios.put(APIURL+'/car_upload', data)
      .then(function(response){

        var data = response.data ;

        let car_upload_id = 0

        if (data.car_upload_id !== undefined) {
          car_upload_id = data.car_upload_id
        }

        var image_url = '';

        if(data.image.includes('https')) {
          image_url = data.image;
        } else {
          image_url = IMAGE_THUMB_URL+data.image;
        }

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }

        var img = new Image();
        img.src= image_url;
        img.addEventListener("load", function () {

          if( input_file_name === 'front_car'){
            that.refs.img_front_car.src = image_url
            that.setState({ front_car_id: car_upload_id })
          }else if(input_file_name === 'front_right'){
            that.refs.img_front_right.src= image_url
            that.setState({ front_right_id: car_upload_id })
            that.setState({ front_right_full: image_url })
          }else if(input_file_name === 'front_left'){
            that.refs.img_front_left.src = image_url
            that.setState({ front_left_id: car_upload_id })
            that.setState({ front_left_full: image_url })
          }else if(input_file_name === 'side_car_left'){
            that.refs.img_side_car_left.src = image_url
            that.setState({ side_car_left_id: car_upload_id })
            that.setState({ side_car_left_full: image_url })
          }else if(input_file_name === 'side_car_right'){
            that.refs.img_side_car_right.src = image_url
            that.setState({ side_car_right_id: car_upload_id })
            that.setState({ side_car_right_full: image_url })
          }else if(input_file_name === 'back_straight'){
            that.refs.img_back_straight.src = image_url
            that.setState({ back_straight_id: car_upload_id })
            that.setState({ back_straight_full: image_url })
          }else if(input_file_name === 'console_car'){
            that.refs.img_console_car.src = image_url
            that.setState({ console_car_id: car_upload_id })
            that.setState({ console_car_full: image_url })
          }else if(input_file_name === 'condition_in_car'){
            that.refs.img_condition_in_car.src = image_url
            that.setState({ condition_in_car_id: car_upload_id })
            that.setState({ condition_in_car_full: image_url })
          }else if(input_file_name === 'engine_car'){
            that.refs.img_engine_car.src = image_url
            that.setState({ engine_car_id: car_upload_id })
            that.setState({ engine_car_full: image_url })
          }else if(input_file_name === 'plate_car'){
            that.refs.img_plate_car.src = image_url
            that.setState({ plate_car_id: car_upload_id })
            that.setState({ plate_car_full: image_url })
          }else if(input_file_name === 'other_car'){
            that.refs.img_other_car.src = image_url
            that.setState({ other_car_id: car_upload_id })
            that.setState({ other_car_full: image_url })
            // add เพิ่ม
          } else if (input_file_name === 'console') {
            // 12
            that.refs.img_console.src = image_url
            that.setState({ console_id: car_upload_id })
            that.setState({ console_full: image_url })
          } else if (input_file_name === 'gear') {
            // 13
            that.refs.img_gear.src = image_url
            that.setState({ gear_id: car_upload_id })
            that.setState({ gear_full: image_url })
          } else if (input_file_name === 'audio_equipment') {
            // 14
            that.refs.img_audio_equipment.src = image_url
            that.setState({ audio_equipment_id: car_upload_id })
            that.setState({ audio_equipment_full: car_upload_id })
          } else if (input_file_name === 'dial') {
            // 15
            that.refs.img_dial.src = image_url
            that.setState({ dial_id: car_upload_id })
            that.setState({ dial_full: car_upload_id })
          } else if (input_file_name === 'catalyticconverter') {
            // 16
            that.refs.img_catalyticconverter.src = image_url
            that.setState({ catalyticconverter_id: car_upload_id })
            that.setState({ catalyticconverter_full: car_upload_id })
          } else if (input_file_name === 'yearoftire') {
            // 17
            that.refs.img_yearoftire.src = image_url
            that.setState({ yearoftire_id: car_upload_id })
            that.setState({ yearoftire_full: car_upload_id })
          } else if (input_file_name === 'chassis') {
            // 18 คัทซี
            that.refs.img_chassis.src = image_url
            that.setState({ chassis_id: car_upload_id })
            that.setState({ chassis_full: car_upload_id })
          } else if (input_file_name === 'other1') {
            // 19 อื่นๆ 1
            that.refs.img_other1.src = image_url
            that.setState({ other1_id: car_upload_id })
            that.setState({ other1_full: car_upload_id })
          } else if (input_file_name === 'other2') {
            // 20 อื่นๆ 2
            that.refs.img_other2.src = image_url
            that.setState({ other2_id: car_upload_id })
            that.setState({ other2_full: car_upload_id })
          } else if (input_file_name === 'other3') {
            // 21 อื่นๆ 3
            that.refs.img_other3.src = image_url
            that.setState({ other3_id: car_upload_id })
            that.setState({ other3_full: car_upload_id })
          } else if (input_file_name === 'plate') {
            that.refs.img_plate.src = image_url
            that.setState({ plate_id: car_upload_id })
            that.setState({ plate_full: car_upload_id })
          } else if (input_file_name === 'engine_oil') {
            that.refs.img_engine_oil.src = image_url
            that.setState({
              engine_oil_id: car_upload_id,
              engine_oil_full: car_upload_id
            })
          } else if (input_file_name === 'front_left_tire_number') {
            that.refs.img_front_left_tire_number.src = image_url
            that.setState({
              front_left_tire_number_id: car_upload_id,
              front_left_tire_number_full: car_upload_id
            })
          } else if (input_file_name === 'front_right_tire_number') {
            that.refs.img_front_right_tire_number.src = image_url
            that.setState({
              front_right_tire_number_id: car_upload_id,
              front_right_tire_number_full: car_upload_id
            })
          } else if (input_file_name === 'back_left_tire_number') {
            that.refs.img_back_left_tire_number.src = image_url
            that.setState({
              back_left_tire_number_id: car_upload_id,
              back_left_tire_number_full: car_upload_id
            })
          } else if (input_file_name === 'back_right_tire_number') {
            that.refs.img_back_right_tire_number.src = image_url
            that.setState({
              back_right_tire_number_id: car_upload_id,
              back_right_tire_number_full: car_upload_id
            })
          } else if (input_file_name === 'car_key') {
            that.refs.img_car_key.src = image_url
            that.setState({
              car_key_id: car_upload_id,
              car_key_full: car_upload_id
            })
          }

        });

      })
      .catch(function(error){
        console.log('error:' + error.response);
        // alert("error:"+error.response);
      });

  }

  handleUploadFile2 (event){

    if(checkGroupPermission(this.props.menuID, this.props.grouppermissions).modified === 0 ) {
      AlertSuccess('สิทธิ์ของท่านไม่สามารถแก้ไขภาพรถได้');
      return
    }

    let input_file_name = event.target.name
    let that = this;

    const data = new FormData();

    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('upload_code', this.props.upload_code);
    data.append('user_updated', this.state.user.user);

    // data.append('car_upload_position', input_file_name);
    // alert(this.state.upload_code)

    if( input_file_name === 'front_car2'){
      this.refs.img_front_car2.src='img/loading.gif'
      this.refs.img_front_car2.height=32
      data.append('car_upload_position', 'front_car2');
      data.append('car_upload_id', this.state.front_car_id2)
    }else if(input_file_name === 'front_right2'){
      this.refs.img_front_right2.src='img/loading.gif'
      data.append('car_upload_position', 'front_right2');
      data.append('car_upload_id', this.state.front_right_id2 )
    }else if(input_file_name === 'front_left2'){
      this.refs.img_front_left2.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left2');
      data.append('car_upload_id', this.state.front_left_id2 )
    }else if(input_file_name === 'back_left2'){
      this.refs.img_back_left2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_left2');
      data.append('car_upload_id', this.state.back_left_id2 );
    }else if(input_file_name === 'back_right2'){
      this.refs.img_back_right2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_right2');
      data.append('car_upload_id', this.state.back_right_id2 );
    }else if(input_file_name === 'side_car_left2'){
      this.refs.img_side_car_left2.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_left2');
      data.append('car_upload_id', this.state.side_car_left_id2 )
    }else if(input_file_name === 'side_car_right2'){
      this.refs.img_side_car_right2.src = 'img/loading.gif'
      data.append('car_upload_position', 'side_car_right2');
      data.append('car_upload_id', this.state.side_car_right_id2 )
    }else if(input_file_name === 'back_straight2'){
      this.refs.img_back_straight2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_straight2');
      data.append('car_upload_id', this.state.back_straight_id2 )
    }else if(input_file_name === 'console_car2'){
      this.refs.img_console_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'console_car2');
      data.append('car_upload_id', this.state.console_car_id2 )
    }else if(input_file_name === 'condition_in_car2'){
      this.refs.img_condition_in_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'condition_in_car2');
      data.append('car_upload_id', this.state.condition_in_car_id2 )
    }else if(input_file_name === 'engine_car2'){
      this.refs.img_engine_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_car2');
      data.append('car_upload_id', this.state.engine_car_id2 )
    }else if(input_file_name === 'plate_car2'){
      this.refs.img_plate_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'plate_car2');
      data.append('car_upload_id', this.state.plate_car_id2 )
    }else if(input_file_name === 'other_car2'){
      this.refs.img_other_car2.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car2');
      data.append('car_upload_id', this.state.other_car_id2 )
    }else if(input_file_name === 'front_right_seat2'){
      // เบาะหน้าขวา
      this.refs.img_front_right_seat2.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_right_seat2');
      data.append('car_upload_id', this.state.front_right_seat_id2 )
    }else if(input_file_name === 'back_right_seat2'){
      // เบาะหลังขวา
      this.refs.img_back_right_seat2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_right_seat2');
      data.append('car_upload_id', this.state.back_right_seat_id2);
    }else if(input_file_name === 'gear2'){
      this.refs.img_gear2.src = 'img/loading.gif'
      data.append('car_upload_position', 'gear2');
      data.append('car_upload_id', this.state.gear_id2);
    }else if(input_file_name === 'audio_equipment2'){
      this.refs.img_audio_equipment2.src = 'img/loading.gif'
      data.append('car_upload_position', 'audio_equipment2');
      data.append('car_upload_id', this.state.audio_equipment_id2);
    }else if(input_file_name === 'dial2'){
      this.refs.img_dial2.src = 'img/loading.gif'
      data.append('car_upload_position', 'dial2');
      data.append('car_upload_id', this.state.dial_id2);
    }else if(input_file_name === 'catalyticconverter2'){
      this.refs.img_catalyticconverter2.src = 'img/loading.gif'
      data.append('car_upload_position', 'catalyticconverter2');
      data.append('car_upload_id', this.state.catalyticconverter_id2);
    }else if(input_file_name === 'yearoftire2'){
      this.refs.img_yearoftire2.src = 'img/loading.gif'
      data.append('car_upload_position', 'yearoftire2');
      data.append('car_upload_id', this.state.yearoftire_id2);
    }else if(input_file_name === 'chassis2'){
      this.refs.img_chassis2.src = 'img/loading.gif'
      data.append('car_upload_position', 'chassis2');
      data.append('car_upload_id', this.state.chassis_id2);
    }else if(input_file_name === 'other_car22'){
      this.refs.img_other_car22.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car22');
      data.append('car_upload_id', this.state.other_car2_id2);
    }else if(input_file_name === 'other_car23'){
      this.refs.img_other_car23.src = 'img/loading.gif'
      data.append('car_upload_position', 'other_car23');
      data.append('car_upload_id', this.state.other_car3_id2);
    }else if(input_file_name === 'engine_oil2'){
      this.refs.img_engine_oil2.src = 'img/loading.gif'
      data.append('car_upload_position', 'engine_oil2');
      data.append('car_upload_id', this.state.engine_oil_id2);
    }else if(input_file_name === 'front_left_tire_number2'){
      this.refs.img_front_left_tire_number2.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_left_tire_number2');
      data.append('car_upload_id', this.state.front_left_tire_number_id2);
    }else if(input_file_name === 'front_right_tire_number2'){
      this.refs.img_front_right_tire_number2.src = 'img/loading.gif'
      data.append('car_upload_position', 'front_right_tire_number2');
      data.append('car_upload_id', this.state.front_right_tire_number_id2);
    }else if(input_file_name === 'back_left_tire_number2'){
      this.refs.img_back_left_tire_number2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_left_tire_number2');
      data.append('car_upload_id', this.state.back_left_tire_number_id2);
    }else if(input_file_name === 'back_right_tire_number2'){
      this.refs.img_back_right_tire_number2.src = 'img/loading.gif'
      data.append('car_upload_position', 'back_right_tire_number2');
      data.append('car_upload_id', this.state.back_right_tire_number_id2);
    }else if(input_file_name === 'car_key2'){
      // car_key2
      this.refs.img_car_key2.src = 'img/loading.gif'
      data.append('car_upload_position', 'car_key2');
      data.append('car_upload_id', this.state.car_key_id2);
    }

    // alert(input_file_name)
    axios.put(APIURL+'/car_upload', data)
      .then(function(response){
        let data = response.data ;
        let car_upload_id = 0

        if (data.car_upload_id !== undefined) {
          car_upload_id = data.car_upload_id
        }
        /*var image_url = IMAGE_THUMB_URL+data.image;

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }*/

        var image_url = '';


        if(data.image.includes('https')) {
          image_url = data.image;
        } else {
          image_url = IMAGE_FULL_URL+data.image;
        }

        if(data.image === undefined) {
          image_url = IMAGE_UPLOAD_ERROR
        } else if (data.image === 'uploaderror') {
          image_url = IMAGE_UPLOAD_ERROR
        }

        var img = new Image();
        img.src= image_url;
        img.addEventListener("load", function () {

          if( input_file_name === 'front_car2'){
            that.refs.img_front_car2.src = image_url
            that.setState({
              front_car_id2: car_upload_id,
              front_car2: image_url
            })
          }else if(input_file_name === 'front_right2'){
            that.refs.img_front_right2.src= image_url
            that.setState({
              front_right_id2: car_upload_id,
              front_right2: image_url
            })
          }else if(input_file_name === 'front_left2'){
            that.refs.img_front_left2.src = image_url
            that.setState({
              front_left_id2: car_upload_id,
              front_left2: image_url
            })
          }else if(input_file_name === 'back_left2'){
            that.refs.img_back_left2.src = image_url
            that.setState({
              back_left_id2: car_upload_id,
              back_left2: image_url
            })
          }else if(input_file_name === 'back_right2'){
            that.refs.img_back_right2.src = image_url
            that.setState({
              back_right_id2: car_upload_id,
              back_right2: image_url
            })
          }else if(input_file_name === 'side_car_left2'){
            that.refs.img_side_car_left2.src = image_url
            that.setState({
              side_car_left_id2: car_upload_id,
              side_car_left2: image_url
            })
          }else if(input_file_name === 'side_car_right2'){
            that.refs.img_side_car_right2.src = image_url
            that.setState({
              side_car_right_id2: car_upload_id,
              side_car_right2: image_url
            })
          }else if(input_file_name === 'back_straight2'){
            that.refs.img_back_straight2.src = image_url
            that.setState({
              back_straight_id2: car_upload_id,
              back_straight2: image_url
            })
          }else if(input_file_name === 'console_car2'){
            that.refs.img_console_car2.src = image_url
            that.setState({
              console_car_id2: car_upload_id,
              console_car2: image_url
            })
          }else if(input_file_name === 'condition_in_car2'){
            that.refs.img_condition_in_car2.src = image_url
            that.setState({
              condition_in_car_id2: car_upload_id,
              condition_in_car2: image_url
            })
          }else if(input_file_name === 'engine_car2'){
            that.refs.img_engine_car2.src = image_url
            that.setState({
              engine_car_id2: car_upload_id,
              engine_car2: image_url
            })
          }else if(input_file_name === 'plate_car2'){
            that.refs.img_plate_car2.src = image_url
            that.setState({
              plate_car_id2: car_upload_id,
              plate_car2: image_url
            })
          }else if(input_file_name === 'other_car2'){
            that.refs.img_other_car2.src = image_url
            that.setState({
              other_car_id2: car_upload_id,
              other_car2: image_url
            })
          }else if(input_file_name === 'front_right_seat2'){
            that.refs.img_front_right_seat2.src = image_url
            that.setState({
              front_right_seat_id2: car_upload_id,
              front_right_seat2: image_url
            })
          }else if(input_file_name === 'back_right_seat2'){
            that.refs.img_back_right_seat2.src = image_url
            that.setState({
              back_right_seat_id2: car_upload_id,
              back_right_seat2: image_url
            })
          }else if(input_file_name === 'gear2'){
            that.refs.img_gear2.src = image_url
            that.setState({
              gear_id2: car_upload_id,
              gear2: image_url
            })
          }else if(input_file_name === 'audio_equipment2'){
            that.refs.img_audio_equipment2.src = image_url
            that.setState({
              audio_equipment_id2: car_upload_id,
              audio_equipment2: image_url
            })
          }else if(input_file_name === 'dial2'){
            that.refs.img_dial2.src = image_url
            that.setState({
              dial_id2: car_upload_id,
              dial2: image_url
            })
          }else if(input_file_name === 'catalyticconverter2'){
            that.refs.img_catalyticconverter2.src = image_url
            that.setState({
              catalyticconverter_id2: car_upload_id,
              catalyticconverter2: image_url
            })
          }else if(input_file_name === 'yearoftire2'){
            that.refs.img_yearoftire2.src = image_url
            that.setState({
              yearoftire_id2: car_upload_id,
              yearoftire2: image_url
            })
          }else if(input_file_name === 'chassis2'){
            that.refs.img_chassis2.src = image_url
            that.setState({
              chassis_id2: car_upload_id,
              chassis2: image_url
            })
          }else if(input_file_name === 'other_car22'){
            that.refs.img_other_car22.src = image_url
            that.setState({
              other_car2_id2: car_upload_id,
              other_car22: image_url
            })
          }else if(input_file_name === 'other_car23'){
            that.refs.img_other_car23.src = image_url
            that.setState({
              other_car3_id2: car_upload_id,
              other_car23: image_url
            })
          }else if(input_file_name === 'engine_oil2'){
            that.refs.img_engine_oil2.src = image_url
            that.setState({
              engine_oil_id2: car_upload_id,
              engine_oil2: image_url
            })
          }else if(input_file_name === 'front_left_tire_number2'){
            that.refs.img_front_left_tire_number2.src = image_url
            that.setState({
              front_left_tire_number_id2: car_upload_id,
              front_left_tire_number2: image_url
            })
          }else if(input_file_name === 'front_right_tire_number2'){
            that.refs.img_front_right_tire_number2.src = image_url
            that.setState({
              front_right_tire_number_id2: car_upload_id,
              front_right_tire_number2: image_url
            })
          }else if(input_file_name === 'back_left_tire_number2'){
            that.refs.img_back_left_tire_number2.src = image_url
            that.setState({
              back_left_tire_number_id2: car_upload_id,
              back_left_tire_number2: image_url
            })
          }else if(input_file_name === 'back_right_tire_number2'){
            that.refs.img_back_right_tire_number2.src = image_url
            that.setState({
              back_right_tire_number_id2: car_upload_id,
              back_right_tire_number2: image_url
            })
          }else if(input_file_name === 'car_key2'){
            that.refs.img_car_key2.src = image_url
            that.setState({
              car_key_id2: car_upload_id,
              car_key2: image_url
            })
          }
        });

      })
      .catch(function(error){
         console.log(error.response);
        // alert("error:"+error.response);
      });

  }

  saveCarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;
  }

  deleteImage(image_position){
    let upload_code = this.props.upload_code

    Swal.fire({
      title: 'ยืนยันการลบภาพ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL + '/car/image'
        let senddata = {
          upload_code,
          upload_position: image_position
        }
        axios.post( url, senddata).then(res => {
          let result = res.data
          if(result === "success") {
            AlertSuccess('ลบข้อมูลเรียบร้อยแล้ว')
            // console.log(res.data)
            let full = image_position +"_full"

            let lastChar = image_position.substr(image_position.length - 1);
            let car_upload_name = image_position+"_id"

            if(lastChar === '2'){
              let tmp = image_position.replace('2', '')
              car_upload_name = tmp+"_id2"
            }


            this.setState({
              [full]:IMAGE_NOPHOTO_URL,
              [image_position]:IMAGE_NOPHOTO_URL,
              [car_upload_name]: 0
            }, ()=>{
              if(image_position === 'front_right'){
                this.refs.img_front_right.src= IMAGE_NOPHOTO_URL
              }else if (image_position === 'front_left'){
                this.refs.img_front_left.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'side_car_left'){
                this.refs.img_side_car_left.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'side_car_right'){
                this.refs.img_side_car_right.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'back_straight'){
                this.refs.img_back_straight.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'console_car'){
                this.refs.img_console_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'condition_in_car'){
                this.refs.img_condition_in_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'engine_car'){
                this.refs.img_engine_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'plate_car'){
                this.refs.img_plate_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other_car'){
                this.refs.img_other_car.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'console'){
                this.refs.img_console.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'gear'){
                this.refs.img_gear.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'audio_equipment'){
                this.refs.img_audio_equipment.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'dial'){
                this.refs.img_dial.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'catalyticconverter'){
                this.refs.img_catalyticconverter.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'yearoftire'){
                this.refs.img_yearoftire.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'chassis'){
                this.refs.img_chassis.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other1'){
                this.refs.img_other1.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other2'){
                this.refs.img_other2.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'other3'){
                this.refs.img_other3.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'plate'){
                this.refs.img_plate.src = IMAGE_NOPHOTO_URL
              }else if (image_position === 'engine_oil'){
                this.refs.img_engine_oil.src = IMAGE_NOPHOTO_URL
              }
            })
            // this.loadCarOwnerData()
          }
        }).catch(error => {
          console.log('error:', error)
        })
      }
    })
  }

  render(){

    let titleAlbum2 = PORT === 9008 ? 'ตอนปล่อยรถ' :  'ก่อนแต่ง';

    return(
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header-darkblue">
              <strong className="text-white">รูปรถ</strong>
            </div>

            <div className="card-block">
      <div className="row">
        <div className="form-group col-sm-4">

          <label htmlFor="name">1) หน้าตรง
             &nbsp;
            <a rel="noopener noreferrer" href={this.state.front_car_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.front_car_full)}
               className={this.state.front_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>
          </label>

          <div style={styles.fileInputWrapper}  >
            <button style={styles.btnFileInput}>
              <img src={this.state.front_car}
                   style={styles.btnFileInputImage}
                   ref="img_front_car"
                   alt="หน้าตรง"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="front_car"
              onChange={this.handleUploadFile}
            />
          </div>

        </div>

        <div className="form-group col-sm-4">

          <label htmlFor="name">2) หน้าซ้าย
            &nbsp;

            <a rel="noopener noreferrer"
                     href={this.state.front_right_full}
                     target="_blank"
                     onClick={(e)=>this.saveCarImage(e,this.state.front_right_full)}
                     className={this.state.front_right_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.front_right_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('front_right')}
            >
              <i className="icon-trash"/>
            </button>
          </label>

          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.front_right}
                   style={styles.btnFileInputImage}
                   ref="img_front_right"
                   alt="มุมขวา"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="front_right"
              onChange={this.handleUploadFile}
            />
          </div>

        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">3) หน้าขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.front_left_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.front_left_full)} className={this.state.front_left_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
          </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.front_left_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('front_left')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.front_left}
                   style={styles.btnFileInputImage}
                   ref="img_front_left"
                   alt="มุมซ้าย"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="front_left"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">4) หลังตรง
            &nbsp;<a rel="noopener noreferrer" href={this.state.side_car_left_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.side_car_left_full)} className={this.state.side_car_left_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.side_car_left_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('side_car_left')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.side_car_left}
                   style={styles.btnFileInputImage}
                   ref="img_side_car_left"
                   alt="หลังตรง"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="side_car_left"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">5) หลังซ้าย
            &nbsp;<a rel="noopener noreferrer" href={this.state.side_car_right_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.side_car_right_full)} className={this.state.side_car_right_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.side_car_right_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('side_car_right')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.side_car_right}
                   style={styles.btnFileInputImage}
                   ref="img_side_car_right"
                   alt="หลังซ้าย"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="side_car_right"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">6) หลังขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.back_straight_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.back_straight_full)} className={this.state.back_straight_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.back_straight_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('back_straight')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.back_straight}
                   style={styles.btnFileInputImage}
                   ref="img_back_straight"
                   alt="หลังขวา"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="back_straight"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">7) ซ้ายตรง
            &nbsp;<a rel="noopener noreferrer" href={this.state.console_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.console_car_full)} className={this.state.console_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.console_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('console_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.console_car}
                   style={styles.btnFileInputImage}
                   ref="img_console_car"
                   alt="คอนโซลรถ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="console_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">8) ขวาตรง
            &nbsp;<a rel="noopener noreferrer" href={this.state.condition_in_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.condition_in_car_full)} className={this.state.condition_in_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.condition_in_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('condition_in_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.condition_in_car}
                   style={styles.btnFileInputImage}
                   ref="img_condition_in_car"
                   alt="สภาพภายในรถ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="condition_in_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">9) เครื่องยนต์
            &nbsp;<a rel="noopener noreferrer" href={this.state.engine_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.engine_car_full)} className={this.state.engine_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.engine_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('engine_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.engine_car}
                   style={styles.btnFileInputImage}
                   ref="img_engine_car"
                   alt="เครื่องยนต์"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="engine_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">10) เบาะหน้าขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.plate_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.plate_car_full)} className={this.state.plate_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.plate_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('plate_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.plate_car}
                   style={styles.btnFileInputImage}
                   ref="img_plate_car"
                   alt="เพลทรถยนต์"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="plate_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">11) เบาะหลังขวา
            &nbsp;<a rel="noopener noreferrer" href={this.state.other_car_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.other_car_full)} className={this.state.other_car_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other_car_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other_car')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.other_car}
                   style={styles.btnFileInputImage}
                   ref="img_other_car"
                   alt="ภาพอื่นๆ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="other_car"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">12) คอนโซล
            &nbsp;
            <a rel="noopener noreferrer"
               href={this.state.console_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.console_full)}
               className={this.state.console_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                 <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.console_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('console')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper} >
            <button style={styles.btnFileInput}>
              <img src={this.state.console}
                   style={styles.btnFileInputImage}
                   ref="img_console"
                   alt="ภาพอื่นๆ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="console"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">13) เกียร์
            &nbsp;<a rel="noopener noreferrer" href={this.state.gear_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.gear_full)} className={this.state.gear_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>
            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.gear_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('gear')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.gear}
                   style={styles.btnFileInputImage}
                   ref="img_gear"
                   alt="เกียร์"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="gear"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">14) เครื่องเสียง
            &nbsp;<a rel="noopener noreferrer" href={this.state.audio_equipment_full} target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.audio_equipment_full)} className={this.state.audio_equipment_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}><span>download</span></a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.audio_equipment_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('audio_equipment')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.audio_equipment}
                   style={styles.btnFileInputImage}
                   ref="img_audio_equipment"
                   alt="เครื่องเสียง"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="audio_equipment"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">15) หน้าปัด
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.dial_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.dial_full)}
               className={this.state.dial_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.dial_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('dial')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.dial}
                   style={styles.btnFileInputImage}
                   ref="img_dial"
                   alt="หน้าปัด"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="dial"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>


        <div className="form-group col-sm-4">
          <label htmlFor="name">16) ท่อแคท
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.catalyticconverter_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.catalyticconverter_full)}
               className={this.state.catalyticconverter_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.catalyticconverter_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('catalyticconverter')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.catalyticconverter}
                   style={styles.btnFileInputImage}
                   ref="img_catalyticconverter"
                   alt="ท่อแคท"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="catalyticconverter"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">17) ปียางรถ
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.yearoftire_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.yearoftire_full)}
               className={this.state.yearoftire_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.yearoftire_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('yearoftire')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.yearoftire}
                   style={styles.btnFileInputImage}
                   ref="img_yearoftire"
                   alt="ท่อแคท"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="yearoftire"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">18) คัทซี
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.chassis_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.chassis_full)}
               className={this.state.chassis_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.chassis_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('chassis')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.chassis}
                   style={styles.btnFileInputImage}
                   ref="img_chassis"
                   alt="คัทซี"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="chassis"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>


        <div className="form-group col-sm-4">
          <label htmlFor="name">19) แผ่นเพลท
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.plate_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.plate_full)}
               className={this.state.plate_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.plate_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('plate')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.plate}
                   style={styles.btnFileInputImage}
                   ref="img_plate"
                   alt="แผ่นเพลท"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="plate"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">20) คอน้ำมันเครื่อง
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.engine_oil_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.engine_oil_full)}
               className={this.state.engine_oil_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.engine_oil_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('engine_oil')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.engine_oil}
                   style={styles.btnFileInputImage}
                   ref="img_engine_oil"
                   alt="คอน้ำมันเครื่อง"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file" name="engine_oil"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">21) เบอร์ยางหน้าซ้าย
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.front_left_tire_number_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.front_left_tire_number_full)}
               className={this.state.front_left_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.front_left_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('front_left_tire_number')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.front_left_tire_number}
                   style={styles.btnFileInputImage}
                   ref="img_front_left_tire_number"
                   alt="เบอร์ยางหน้าซ้าย"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="front_left_tire_number"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">22) เบอร์ยางหน้าขวา
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.front_right_tire_number_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.front_right_tire_number_full)}
               className={this.state.front_right_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.front_right_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('front_right_tire_number')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.front_right_tire_number}
                   style={styles.btnFileInputImage}
                   ref="img_front_right_tire_number"
                   alt="เบอร์ยางหน้าขวา"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="front_right_tire_number"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">23) เบอร์ยางหลังซ้าย
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.back_left_tire_number_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.back_left_tire_number_full)}
               className={this.state.back_left_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.back_left_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('back_left_tire_number')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.back_left_tire_number}
                   style={styles.btnFileInputImage}
                   ref="img_back_left_tire_number"
                   alt="เบอร์ยางหลังซ้าย"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="back_left_tire_number"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">24) เบอร์ยางหลังขวา
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.back_right_tire_number_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.back_right_tire_number_full)}
               className={this.state.back_right_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.back_right_tire_number_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('back_right_tire_number')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.back_right_tire_number}
                   style={styles.btnFileInputImage}
                   ref="img_back_right_tire_number"
                   alt="เบอร์ยางหลังขวา"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="back_right_tire_number"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>


        <div className="form-group col-sm-4">
          <label htmlFor="name">25) กุญแจรถ
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.car_key_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.car_key_full)}
               className={this.state.car_key_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.car_key_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('car_key')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.car_key}
                   style={styles.btnFileInputImage}
                   ref="img_car_key"
                   alt="กุญแจรถ"
              />
            </button>
            <input
              style={styles.fileInput}
              type="file"
              name="car_key"
              onChange={this.handleUploadFile}
            />
          </div>
        </div>


        <div className="form-group col-sm-4">
          <label htmlFor="name">26) อื่นๆ 1
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.other1_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.other1_full)}
               className={this.state.other1_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other1_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other1')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.other1}
                   style={styles.btnFileInputImage}
                   ref="img_other1"
                   alt="อื่นๆ 1"
              />
            </button>
            <input
                style={styles.fileInput}
                type="file" name="other1"
                onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">27) อื่นๆ 2
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.other2_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.other2_full)}
               className={this.state.other2_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other2_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other2')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.other2}
                   style={styles.btnFileInputImage}
                   ref="img_other2"
                   alt="อื่นๆ 2"
              />
            </button>
            <input
                style={styles.fileInput}
                type="file" name="other2"
                onChange={this.handleUploadFile}
            />
          </div>
        </div>

        <div className="form-group col-sm-4">
          <label htmlFor="name">28) อื่นๆ 3
            &nbsp;
            <a rel="noopener noreferrer" href={this.state.other3_full}
               target="_blank"
               onClick={(e)=>this.saveCarImage(e,this.state.other3_full)}
               className={this.state.other3_full===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
              <span>download</span>
            </a>

            &nbsp;&nbsp;
            <button type="button"
                    className={this.state.other3_full===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                    onClick={()=> this.deleteImage('other3')}
            >
              <i className="icon-trash"/>
            </button>
          </label>
          <div style={styles.fileInputWrapper}>
            <button style={styles.btnFileInput}>
              <img src={this.state.other3}
                   style={styles.btnFileInputImage}
                   ref="img_other3"
                   alt="อื่นๆ 3"
              />
            </button>
            <input
                style={styles.fileInput}
                type="file" name="other3"
                onChange={this.handleUploadFile}
            />
          </div>
        </div>



      </div>
            </div>
          </div>
        </div>


        {/*<div className={PORT === '9006' || PORT === '9000' ? "col-lg-12" : "hidden" } >*/}

        <div className="col-lg-12" >
          <div className="card">
            <div className="card-header-darkblue">
              <strong className="text-white">รูปรถ({titleAlbum2})</strong>
            </div>

            <div className="card-block">
              <div className="row">
                <div className="form-group col-sm-4">
                  <label htmlFor="name">1) หน้าตรง ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.front_car2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.front_car2)}
                       className={this.state.front_car2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.front_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}  >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_car2}
                           style={styles.btnFileInputImage}
                           ref="img_front_car2"
                           alt="หน้าตรง"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="front_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>


                <div className="form-group col-sm-4">
                  <label htmlFor="name">2) หน้าซ้าย ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.front_left2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.front_left2)}
                       className={this.state.front_left2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.front_left2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_left2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_left2}
                           style={styles.btnFileInputImage}
                           ref="img_front_left2"
                           alt="มุมซ้าย"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="front_left2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>


                <div className="form-group col-sm-4">

                  <label htmlFor="name">3) หน้าขวา ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.front_right2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.front_right2)}
                       className={this.state.front_right2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.front_right2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_right2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_right2}
                           style={styles.btnFileInputImage}
                           ref="img_front_right2"
                           alt="มุมขวา"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="front_right2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>

                </div>


                <div className="form-group col-sm-4">
                  <label htmlFor="name">4) หลังตรง ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.back_straight2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.back_straight2)}
                       className={this.state.back_straight2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.back_straight2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('back_straight2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.back_straight2}
                           style={styles.btnFileInputImage}
                           ref="img_back_straight2"
                           alt="มุมตรง"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="back_straight2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">5) หลังซ้าย ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.back_left2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.back_left2)}
                       className={this.state.back_left2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.back_left2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('back_left2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.back_left2}
                           style={styles.btnFileInputImage}
                           ref="img_back_left2"
                           alt="หลังซ้าย"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="back_left2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">6) หลังขวา ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.back_right2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.back_right2)}
                       className={this.state.back_right2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.back_right2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('back_right2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.back_right2}
                           style={styles.btnFileInputImage}
                           ref="img_back_right2"
                           alt="หลังขวา"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="back_right2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">7 ) ซ้ายตรง ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.side_car_left2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.side_car_left2)}
                       className={this.state.side_car_left2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.side_car_left2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('side_car_left2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.side_car_left2}
                           style={styles.btnFileInputImage}
                           ref="img_side_car_left2"
                           alt="ด้านซ้าย"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="side_car_left2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">8) ขวาตรง ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.side_car_right2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.side_car_right2)}
                       className={this.state.side_car_right2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.side_car_right2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('side_car_right2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.side_car_right2}
                           style={styles.btnFileInputImage}
                           ref="img_side_car_right2"
                           alt="ด้านขวา"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="side_car_right2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">9) เครื่องยนต์ ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.engine_car2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.engine_car2)}
                       className={this.state.engine_car2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.engine_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('engine_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.engine_car2}
                           style={styles.btnFileInputImage}
                           ref="img_engine_car2"
                           alt="เครื่องยนต์"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="engine_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">10) เบาะหน้าขวา ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.front_right_seat2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.front_right_seat2)}
                       className={this.state.front_right_seat2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.front_right_seat2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_right_seat2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_right_seat2}
                           style={styles.btnFileInputImage}
                           ref="img_front_right_seat2"
                           alt="เบาะหน้าขวา"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="front_right_seat2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">11) เบาะหลังขวา ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.back_right_seat2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.back_right_seat2)}
                       className={this.state.back_right_seat2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.back_right_seat2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('back_right_seat2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.back_right_seat2}
                           style={styles.btnFileInputImage}
                           ref="img_back_right_seat2"
                           alt="เบาะหลังขวา"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="back_right_seat2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>


                <div className="form-group col-sm-4">
                  <label htmlFor="name">12) คอนโซล ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.console_car2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.console_car2)}
                       className={this.state.console_car2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.console_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('console_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.console_car2}
                           style={styles.btnFileInputImage}
                           ref="img_console_car2"
                           alt="คอนโซลรถ"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="console_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">13) เกียร์  ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.gear2} target="_blank"
                             onClick={(e)=>this.saveCarImage(e,this.state.gear2)}
                             className={this.state.gear2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                              <span>download</span>
                    </a>
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.gear2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('gear2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.gear2}
                           style={styles.btnFileInputImage}
                           ref="img_gear2"
                           alt="เกียร์"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="gear2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">14) เครื่องเสียง ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer"
                             href={this.state.audio_equipment2}
                             target="_blank" onClick={(e)=>this.saveCarImage(e,this.state.audio_equipment2)}
                             className={this.state.audio_equipment2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.audio_equipment2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('audio_equipment2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.audio_equipment2}
                           style={styles.btnFileInputImage}
                           ref="img_audio_equipment2"
                           alt="เครื่องเสียง"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="audio_equipment2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">15) หน้าปัด ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.dial2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.dial2)}
                       className={this.state.dial2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.dial2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('dial2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.dial2}
                           style={styles.btnFileInputImage}
                           ref="img_dial2"
                           alt="หน้าปัด"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="dial2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>


                <div className="form-group col-sm-4">
                  <label htmlFor="name">16) ท่อแคท ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.catalyticconverter2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.catalyticconverter2)}
                       className={this.state.catalyticconverter2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.catalyticconverter2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('catalyticconverter2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.catalyticconverter2}
                           style={styles.btnFileInputImage}
                           ref="img_catalyticconverter2"
                           alt="ท่อแคท"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="catalyticconverter2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">17) ปียางรถ ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.yearoftire2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.yearoftire2)}
                       className={this.state.yearoftire2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.yearoftire2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('yearoftire2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.yearoftire2}
                           style={styles.btnFileInputImage}
                           ref="img_yearoftire2"
                           alt="ปียางรถ"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="yearoftire2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">18) คัทซี ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.chassis2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.chassis2)}
                       className={this.state.chassis2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.chassis2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('chassis2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.chassis2}
                           style={styles.btnFileInputImage}
                           ref="img_chassis2"
                           alt="คัทซี"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="chassis2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>


                <div className="form-group col-sm-4">
                  <label htmlFor="name">19) แผ่นเพลท ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.plate_car2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.plate_car2)}
                       className={this.state.plate_car2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.plate_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('plate_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.plate_car2}
                           style={styles.btnFileInputImage}
                           ref="img_plate_car2"
                           alt="เพลทรถยนต์"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="plate_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">20) คอน้ำมันเครื่อง ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.engine_oil2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.engine_oil2)}
                       className={this.state.engine_oil2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.engine_oil2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('engine_oil2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.engine_oil2}
                           style={styles.btnFileInputImage}
                           ref="img_engine_oil2"
                           alt="คอน้ำมันเครื่อง"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="engine_oil2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">21) เบอร์ยางหน้าซ้าย ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.front_left_tire_number2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.front_left_tire_number2)}
                       className={this.state.front_left_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.front_left_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_left_tire_number2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_left_tire_number2}
                           style={styles.btnFileInputImage}
                           ref="img_front_left_tire_number2"
                           alt="เบอร์ยางหน้าซ้าย2"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="front_left_tire_number2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">22) เบอร์ยางหน้าขวา ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.front_right_tire_number2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.front_right_tire_number2)}
                       className={this.state.front_right_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.front_right_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('front_right_tire_number2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.front_right_tire_number2}
                           style={styles.btnFileInputImage}
                           ref="img_front_right_tire_number2"
                           alt="เบอร์ยางหน้าขวา2"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="front_right_tire_number2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">23) เบอร์ยางหลังซ้าย ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.back_left_tire_number_full}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.back_left_tire_number2)}
                       className={this.state.back_left_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.back_left_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('back_left_tire_number2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.back_left_tire_number2}
                           style={styles.btnFileInputImage}
                           ref="img_back_left_tire_number2"
                           alt="เบอร์ยางหลังซ้าย2"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="back_left_tire_number2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">24) เบอร์ยางหลังขวา ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.back_right_tire_number2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.back_right_tire_number2)}
                       className={this.state.back_right_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.back_right_tire_number2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('back_right_tire_number2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.back_right_tire_number2}
                           style={styles.btnFileInputImage}
                           ref="img_back_right_tire_number2"
                           alt="เบอร์ยางหลังซ้าย2"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="back_right_tire_number2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>


                <div className="form-group col-sm-4">
                  <label htmlFor="name">25) กุญแจรถ ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.car_key2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.car_key2)}
                       className={this.state.car_key2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>

                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.car_key2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('car_key2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper}>
                    <button style={styles.btnFileInput}>
                      <img src={this.state.car_key2}
                           style={styles.btnFileInputImage}
                           ref="img_car_key2"
                           alt="กุญแจรถ"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="car_key2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                {/*<div className="form-group col-sm-4">
                  <label htmlFor="name">xx) สภาพภายในรถ ({titleAlbum2})
                    &nbsp;&nbsp;
                    <button type="button"
                            className={this.state.condition_in_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('condition_in_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.condition_in_car2}
                           style={styles.btnFileInputImage}
                           ref="img_condition_in_car2"
                           alt="สภาพภายในรถ"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file" name="condition_in_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>*/}



                <div className="form-group col-sm-4">
                  <label htmlFor="name">26) อื่นๆ 1 ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.other_car2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.other_car2)}
                       className={this.state.other_car2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.other_car2===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('other_car2')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    {this.state.other_car2}
                    <button style={styles.btnFileInput}>
                      <img src={this.state.other_car2}
                           style={styles.btnFileInputImage}
                           ref="img_other_car2"
                           alt="อื่นๆ 1"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="other_car2"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-4">
                  <label htmlFor="name">27) อื่นๆ 2 ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.other_car22}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.other_car22)}
                       className={this.state.other_car22===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.other_car22===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('other_car22')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.other_car22}
                           style={styles.btnFileInputImage}
                           ref="img_other_car22"
                           alt="ภาพอื่นๆ2"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="other_car22"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>


                <div className="form-group col-sm-4">
                  <label htmlFor="name">28) อื่นๆ 2 ({titleAlbum2})
                    &nbsp;
                    <a rel="noopener noreferrer" href={this.state.other_car23}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.other_car23)}
                       className={this.state.other_car23===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>download</span>
                    </a>
                    &nbsp;
                    <button type="button"
                            className={this.state.other_car23===IMAGE_NOPHOTO_URL? 'hidden' : 'btn btn-sm btn-danger h-25'}
                            onClick={()=> this.deleteImage('other_car23')}
                    >
                      <i className="icon-trash"/>
                    </button>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.other_car23}
                           style={styles.btnFileInputImage}
                           ref="img_other_car23"
                           alt="ภาพอื่นๆ3"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="other_car23"
                      onChange={this.handleUploadFile2}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarEditAlbum);

const styles = {
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 120,
    width: 200,
  },
  btnFileInput: {
    height: 120,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 120,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 120,
    width: 200,
    cursor: 'pointer'
  }
}
