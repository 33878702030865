import axios from 'axios'
import { APIURL } from '../config/config'

export const getGroupPermission = (type, menuID) => {
  if (type !== undefined) {
    return axios.get(APIURL + '/grouppermission/menu/' + type + '/' + menuID)
  }
}

export const checkGroupPermission = (menuID, grouppermissions) => {
  const permission = grouppermissions.filter(val => val.menu_id === menuID)

  if (permission.length > 0) {
    return permission[0]
  } else {
    return false
  }
}

