import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <a href="https://www.bosscarsoft.com/" rel="noopener noreferrer"  target="_blank">
          BossCarSoft

        </a> &copy; 2018-2022 v1.2.97 2023-04-29 14:27

        <span className="float-right">Powered by
          <a href="https://www.appxar.co" rel="noopener noreferrer"  target="_blank">&nbsp;Apzar Solution Co.,Ltd.</a>
        </span>

      </footer>
    )
  }
}

export default Footer;
