import React, { Component } from 'react';
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../config/config'
import axios from 'axios'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

export default class TurnCarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      lists: [],
      search_car_name: ''
    }
    this.getDataCarSearchWithCriteria = this.getDataCarSearchWithCriteria.bind(this);
    this.onRefreshCar = this.onRefreshCar.bind(this);
  }

  componentDidMount() {
    // console.log('---componentDidMount')
    this.getDataCarSearchWithCriteria();
  }

  getDataCarSearchWithCriteria () {
    let search_text = this.state.search_car_name
    // console.log('search_text=', search_text)
    let data = {
      search_text,
      branch_id: '',
      car_status_id: '',
      car_structure_id: '',
      order_by: '',
      car_brand_id: ''
    }

    let uri = APIURL + '/car/criteria';

    this.setState({isLoading: true}, () => {
      axios.post(uri, data)
        .then(res => {
          let car_id = Number(this.props.car_id)
          console.log('car_id=', car_id, typeof car_id)

          let lists = res.data
          let listsAll = lists.filter(car => {
            return car.car_id !== car_id
          })

          console.log('lists.length=', lists.length)
          console.log('listsAll.length=', listsAll.length)

          this.setState({
            lists: listsAll,
            isLoading: false
          });
        }).catch(error => {
           this.setState({lists: [], isLoading: false})
      });
    })

  }

  onRefreshCar() {
    this.setState({
      search_car_name: ''
    }, () => {
      this.getDataCarSearchWithCriteria();
    })
  }

  render () {
    // let page = 1
    let width = 980
    let imgWidth = 120

    return <>
      <Modal isOpen={this.props.isOpenTurnCarModal}
             toggle={this.props.onToggle}
             className={'modal-lg '}
             style={width<=980 ? {maxWidth:800}: {maxWidth:1200}}
      >
        <ModalBody>
            <div className="card card-accent-primary">
              <div className="card-header">
                <div className="form-inline">

                <strong className="text-title mt-1">ค้นหารถ</strong>

                <input
                  name="search_car_name"
                  value={this.state.search_car_name}
                  onChange={(e) => {
                    this.setState({
                      search_car_name: e.target.value
                    })
                  }}
                  style={styles.searchCar}
                  /*className={width<=980 ? 'hidden' : 'form-control'}*/
                  className="form-control ml-1"
                  placeholder="ค้นหาชื่อรถ ทะเบียนรก"
                  type="text"
                />
                {/*&nbsp;*/}
                <button
                  className="btn btn-primary btn-sm ml-1"
                  onClick={() => this.getDataCarSearchWithCriteria()}
                  style={styles.buttonHeight}
                >
                  <i className="icon-search"/>ค้นหา
                </button>

                  <button
                    className="btn btn-sm btn-head-bar ml-1"
                    onClick={this.onRefreshCar}
                    style={styles.buttonHeight}
                  >
                    <i className="icon-refresh"/>
                  </button>

                </div>
              </div>
              <div className="card-block">
                <div className="row">
                  <div className="col-lg-12">
                    <table className="table table-striped">
                      <thead>
                      <tr>
                        <th className="text-center">รูปรถ</th>
                        <th className="text-center">ชื่อรถ</th>
                        <th className="text-center" width='80px'>จัดการ</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.lists.map((car, index) => {
                          let image_url = ''
                          if (car.car_upload_filename !== null) {
                            if (car.car_upload_filename.includes('https')) {
                              image_url = car.car_upload_filename
                            } else {
                              image_url = IMAGE_FULL_URL + car.car_upload_filename
                            }
                          } else {
                            image_url = IMAGE_NOPHOTO_URL
                          }

                          return (
                            <tr className="text-center" key={index}>
                              <td>
                                <img src={image_url}
                                     style={{'cursor' : 'pointer', 'width': imgWidth, 'height': 'auto'}}
                                     alt="car"/>
                              </td>
                              <td className="text-center">
                                <strong>{car.car_name}
                                  &nbsp;&nbsp;
                                  <span className="badge"
                                        style={{backgroundColor: car.car_status_color}}>{car.car_status_name}
                                  </span>
                                </strong>
                                <br/>
                                {
                                  car.car_license_plate_new === '' ? null : (
                                    <>
                                      <strong> ทะเบียนรถ : {car.car_license_plate_new}</strong>
                                      &nbsp; <strong>จ.{car.province_name_new}</strong>
                                    </>
                                  )
                                }

                                {
                                  car.car_license_plate_new !== '' && car.car_license_plate_old !== '' && car.car_license_plate_new !== car.car_license_plate_old ? <br/> : null
                                }

                                {
                                  car.car_license_plate_new !== car.car_license_plate_old && car.car_license_plate_old !== '' ? (
                                      <>
                                        <strong> &nbsp;&nbsp; ทะเบียนเดิม : {car.car_license_plate_old} &nbsp;จ.{car.province_name_old}</strong>
                                      </>
                                    )
                                    : null
                                }

                              </td>
                              <td className="text-center">
                                 <button type="button"
                                         className="btn btn-primary btn-sm"
                                         onClick={(e) => {
                                              this.props.onSelectTurnCar(car)
                                         }}
                                 >
                                   เลือก
                                 </button>
                              </td>
                            </tr>
                          )

                        })
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
      </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.onToggle}>ยกเลิก</Button>
        </ModalFooter>
      </Modal>
    </>
  }

}

const styles = {
  searchCar: {
    marginLeft: 0,
    marginTop: 5,
    marginBottom: -8,
    width: 180,
    borderRadius:5,
    display: 'inline'
  },
  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginTop: 5,
    marginBottom: -8,
  },
}
