import React, { Component } from 'react';
import axios from 'axios';
import { APIURL } from '../../config/config';
import NumberFormat from 'react-number-format';

const port = Number(window.env.PORT);

class OutstandingBalanceComponent extends  Component {
  constructor (props) {
    super(props)
    this.state = {
      sum_pay: 0,
      is_load: false,
      remain: 0
    }

    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this)
  }

  componentDidMount () {
    this._isMounted = true;
    this._isMounted && this.loadDataCarSellPay()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadDataCarSellPay () {

    if (this.props.car_id === '' || this.props.customer_id === '') {
      return
    }

    let car_sell = this.props.car_sell

    this._isMounted && axios.get(APIURL + '/car_sell_pay/buy/' + this.props.car_id + '/' + this.props.customer_id)
      .then(res => {
        // car_sell_pay.car_sell_pay_count
        let car_sell_pay_list = res.data
        let sum_pay = 0

        if (car_sell_pay_list.length > 0) {
          sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)
        }

        // ค่างวดล่างหน้าธนาคาร
        // let bank_advance_payments = car_sell_pay_list.filter(pay => pay.car_sell_list_pay_name === 'ค่างวดล่วงหน้า')
        // let is_bank_advance_payment = bank_advance_payments.length > 0 ? true : false

        let insurance_price = (car_sell.insurance_buy ==='ประกันแถม') ? 0 : car_sell.insurance_price //ราคาประกัน
        let rate_finance = car_sell.rate_finance
        let real_sell_price = car_sell.real_sell_price
        let cost_finance = car_sell.cost_finance
        let cost_transfer = car_sell.cost_transfer
        let pay_advance_finance = car_sell.pay_advance_finance
        let insurance_pa = car_sell.insurance_pa
        let vat_down = Number(car_sell.vat_down) // VAT เงินดาวน์
        // ส่วนลดเงินดาวน์
        let sell_discount = Number(car_sell.sell_discount)
        let gearbox_amount = Number(car_sell.gearbox_amount)
        let debt_insurance_amount = Number(car_sell.debt_insurance_amount)
        // let sell_discount = Number(car_sell.sell_discount)

        if (port === 9005) {
          // pisan ไม่รวม pa ในรวมออกรถ
          insurance_pa = 0
        }

        let total_outof_car = (Number(real_sell_price) - Number(rate_finance) - Number(sell_discount)) + Number(insurance_price) + Number(cost_finance) + Number(cost_transfer) + Number(pay_advance_finance) + Number(insurance_pa) + gearbox_amount + debt_insurance_amount + vat_down

        let bank_finance_id = this.props.bank_finance_id
       // let total_outof_car = car_sell.total_outof_car

        // ยอดที่จ่ายเข้ามาทั้งหมด
        let balance_debt = this.props.balance_debt

        // let remain = balance_debt - sum_pay

        let remain = total_outof_car - sum_pay
        let insurance_buy = car_sell.insurance_buy
        // let insurance_price = car_sell.insurance_price
        // let pay_advance_finance  = car_sell.pay_advance_finance
        // let insurance_pa = car_sell.insurance_pa

        // remain += Number(insurance_pa)
        // remain += Number(pay_advance_finance)
        // sompongyontakan จ่ายงวดแรกของให้ finance
        // //  ค่างวดล่างหน้าธนาคาร ถ้ามี เอาไปคิดเงินคงเหลือด้วย
        // if (is_bank_advance_payment) {
        //   remain += Number(pay_advance_finance)
        // }

        // รวมจ่าย PA
        if (insurance_buy === 'ซื้อเอง') {
          // remain += Number(insurance_price)
        }

        if (bank_finance_id === 1) {
          remain = balance_debt
        }

        if (total_outof_car === sum_pay) {
          remain = 0
        }

        if (sum_pay === real_sell_price) {
          remain = 0
        }

        this._isMounted && this.setState({
          sum_pay: sum_pay,
          is_load: true,
          // is_bank_advance_payment: is_bank_advance_payment,
          remain: remain
        }, () => {
           // update ยาก
           // this.props.setCarSellRemain(remain, car_sell.car_sell_id)
        })
      }).catch(error => {
       console.log('error:', error)
      // this.setState({car_sell_pay_list: []})
    })
  }

  render () {

    const { is_load, sum_pay, remain } = this.state
    let isShowText = this.props.isShowText
    let balance_debt_input = this.props.balance_debt_input
    let total_outof_car = this.props.total_outof_car === undefined ? 0 : Number(this.props.total_outof_car)

    return (
      <>
        {
          is_load === true ? (
                  isShowText === false ?
                    <>
                      <span className={"text-primary"}>&nbsp;
                        <NumberFormat
                            value={remain <= 0 || (remain - total_outof_car <= 0)  ? '-' : remain - total_outof_car}
                            displayType={'text'} thousandSeparator={true}
                            prefix={'฿'}/>
                      </span>
                      {
                        balance_debt_input === true ? (
                          <input readOnly
                                 type="text"
                                 className="form-control hidden"
                                 id="balance_debt"
                                 name="balance_debt"
                                 ref="balance_debt"
                                 placeholder=""
                                 value={remain - total_outof_car}
                                 onChange={()=>{}}
                          />
                        ) : null
                      }
                    </>
                    :
                      <span className={"text-primary"}>ยอดคงเหลือค้างชำระ&nbsp;
                        <NumberFormat
                            value={remain <= 0 ? '-' : remain}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'฿'}
                        />
                      </span>
          )
            :
            '...'
        }
      </>
    )
  }

}
export default OutstandingBalanceComponent
