import React, { Component } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../config/config';
import { save } from 'save-file';
import { CarUtil } from '../../utils/carUtil';
import { connect } from 'react-redux'
import { NumberUtil } from '../../utils/number-util'
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission'
import { AlertWarning } from '../Alert/Alert'

class CarInReport extends Component{
  constructor (props) {
    super(props);
    this.state = {
      menuID: [107],
      grouppermissions: [],
      user: {},
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      lists: [],
      data_by: 'all', // all, byDate
      branches: [],
      branch_id: ''
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadCarInData = this.loadCarInData.bind(this)
    this.exportCarInData = this.exportCarInData.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)
    this.showBranchSummary = this.showBranchSummary.bind(this)
    this.loadGroupPermission = this.loadGroupPermission.bind(this)
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID
    if (type === undefined) {

      return
    }

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    }).catch(e => {
      console.log('error: ', e)
      this.setState({
        grouppermissions: []
      })
    })
  }

  componentDidMount () {
     this.loadBranchData()
    this.loadGroupPermission()
  }

  // defaultChecked={this.state.data_by === 'byDate'}
  // defaultChecked={this.state.data_by === 'all'}

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  loadCarInData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: true
    })

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id
    }

    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)
    axios.post(APIURL + '/report/carin', data)
      .then(res => {
        // console.log(res.data)
        this.setState({
          isLoading: false,
          lists: res.data
        }, () => {
          this.showBranchSummary()
        })

      }).catch(error => {
       console.log('error:', error)
    })

  }

  showBranchSummary() {

  }

  async exportCarInData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    let viewed = checkGroupPermission(107, this.state.grouppermissions).viewed
    // console.log('viewed=', viewed, typeof viewed)
    // return

    this.setState({
      isLoading: true
    })

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id,
      viewed: viewed
    }

    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)
    axios.post(APIURL + '/report/carinexport', data)
      .then(async res => {

        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'รายงานสต๊อกรถ_export_'+currentDatetime+'.xlsx'

        this.setState({
          isLoading: false
        })

        await save(excelBuffer, fileName)

      }).catch(error => {
      console.log('error carinexport:', error)
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branches: res.data}, () => this.loadCarInData())
      }).catch(error => {
      this.setState({branches: []})
    })
  }

  render () {
    const {
      lists
      // sum_price,
      // sum_car_cost,
      // sum_car_cost_repair,
      // sum_car_cost_other,
      // sum_profit
    } = this.state

    // const usertype = this.props.type
    // console.log('usertype=', usertype)
    const viewed = checkGroupPermission(107, this.state.grouppermissions).viewed

    const emptyBody = <tr><td colSpan={17} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    let sumCarCost = 0
    let sumCarSalePrice = 0
    const listRows = lists.map((car, index) => {
      const car_date_in = moment(car.car_date_in).locale('th').format('LL')
      // const car_sell_date = car.car_sell_date === null ? '-' : moment(car.car_sell_date).locale('th').format('LL')
      /* const car_cost = car.car_cost
      const car_cost_repair = car.car_cost_repair
      const car_cost_other = car.car_cost_other
      // const gross_profit = car.real_sell_price - (car_cost+car_cost_repair+car_cost_other)
      const real_sell_price = car.real_sell_price === null ? '-': car.real_sell_price*/
      sumCarCost += Number(car.car_cost)
      sumCarSalePrice += Number(car.car_sale_price)
      let qtyDate = 0
      let start = moment(car.car_date_in)
      if (car.car_sell_date === null) {
        // วันจอดถึงวันปัจจุบัน
        let end = moment(new Date())
        qtyDate = end.diff(start, 'days')
      } else {
        // วันจอดถึงวันขาย
        let end = moment(car.car_sell_date)
        qtyDate = end.diff(start, 'days')
      }

    // <td className="text-right">{real_sell_price}</td>
    // <td className="text-right">{car.car_cost.toLocaleString()}</td>
    // <td className="text-right">{car.car_cost_repair.toLocaleString()}</td>
    // <td className="text-right">{car.car_cost_other.toLocaleString()}</td>

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td>{car.car_name}</td>
          <td>{car.car_brand_name}</td>
          <td>{car.car_structure_name}</td>
          <td className="text-center">{car.car_license_plate_new} {car.province_name_new}</td>
          <td className="text-center">{car.car_license_plate_old} {car.province_name_old}</td>
          <td>{car_date_in}</td>
          <td>{car.buy_car_from}</td>
          <td className="text-center">{CarUtil.convertDepositRegistration(car.deposit_registration_id)}</td>
          <td>
            { car.receipt_date !==null  && car.receipt_date!=='0000-00-00'? moment(car.receipt_date).locale('th').format('LL') : '-'}
          </td>
          <td className="text-center">{CarUtil.convertLicenseIdToName(car.liciense_status_id)}</td>
          <td >{car.finance_name}</td>
          <td  >{car.branch_name}</td>
          <td className="text-center">{qtyDate}</td>
          <td className="text-center">{car.car_status_name}</td>
          <td className={ viewed ? "text-center" : "hidden"} > {NumberUtil.addCommas(String(car.car_cost))} </td>
          <td className={ viewed ? "text-center" : "hidden"} > {NumberUtil.addCommas(String(car.car_sale_price))} </td>
        </tr>
      )
    })


    let sumRow = <tr>
      <td colSpan={15}  className="text-right font-weight-bold" >รวม</td >
      <td className="font-weight-bold" >{NumberUtil.addCommas(String(sumCarCost))}</td>
      <td className="font-weight-bold" >{NumberUtil.addCommas(String(sumCarSalePrice))}</td>
    </tr>

    const tableRows = lists.length === 0 ? emptyBody : listRows

    let car_branch_list = this.state.branches.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />

        <div className="card-block" style={styles.overflowY}>
          <div className="row">

              <div className="card card-accent-primary">

                <div className="card-header">
                  <div className="row col-12 mt-1">
                    <div className="col-6">
                      <strong className="text-title">
                        {
                          this.state.data_by === 'allWithSell' ? 'รายงานการซื้อรถ (ตามวันที่ + ทั้งหมด(รวมขายแล้ว))' : 'รายงานสต๊อกรถ (เฉพาะรถที่ยังไม่ขาย)'
                        }
                        </strong>
                    </div>
                    <div className="col-3">

                    </div>

                      {
                        this.state.lists.length === 0 ? '': (
                          <div className="col-3 text-center pt-2 pb-1 bg-primary">
                              <h5>
                                พบ&nbsp; {this.state.lists.length} &nbsp;คัน
                              </h5>
                          </div>
                        )
                      }

                  </div>
                </div>

                <div className="card-block">

                  <div className="row mt-1">
                    <div className="mt-1 ml-4">
                      จาก
                    </div>

                    <div className="form-group  ml-2" style={{width: 130}} >
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                   {/* <div className="col-1 text-center">
                      ถึง
                    </div>*/}
                    <div className="form-group ml-1" style={{width: 130}}>
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group ml-1" style={{width: 130}}>
                      <select className="form-control" id="branch_id" name="branch_id"
                        onChange={(e) => {
                          this.setState({
                            branch_id: e.target.value
                          })
                        }}
                      >
                        <option value=''> ตามสาขา </option>
                        {car_branch_list}
                      </select>
                    </div>

                    <div className="form-group ml-1" style={{width: 160}}>
                      <select className="form-control"
                              id="data_by" name="data_by"
                              onChange={this.handleOnChange}
                              value={this.state.data_by}
                      >
                        <option value='byDate'> ตามวันที่ </option>
                        <option value='all'> ทั้งหมด(ยังไม่ขาย) </option>
                        <option value='allWithSell'> ตามวันที่ + ทั้งหมด(รวมขายแล้ว) </option>
                      </select>
                    </div>

                    {/*<div className="form-group mt-1 ml-3">
                      <input type="radio"
                             name="data_by"
                             value="byDate"
                             className="btn btn-primary"
                             checked={this.state.data_by === 'byDate'}
                             onChange={this.handleOnChange}
                      /> &nbsp;<span style={{marginTop: -4}}>ตามวันที่</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="data_by"
                             value="all"
                             className="btn btn-primary"
                             checked={this.state.data_by === 'all'}
                             onChange={this.handleOnChange}
                      /> &nbsp;<span style={{marginTop: -4}}>ทั้งหมด</span>
                    </div>*/}

                    <div className="col-1 ml-4 mr-1">
                      <button type="button" style={{width: 80}} className="btn btn-sm btn-primary" onClick={() => this.loadCarInData()} > ตกลง </button>
                    </div>

                    <div className="col-1">
                      <button type="button" style={{width: 80}} className="btn btn-sm btn-success" onClick={() => this.exportCarInData()} > Excel </button>
                    </div>

                  </div>

                  <div className="row mt-1">
                    <div className="col-12">
                      <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                          <th className="text-center">ลำดับ</th>
                          <th style={{width: 150}} className="text-center">ชื่อรถ</th>
                          <th style={{width: 50}} className="text-center">ยี่ห้อ</th>
                          <th style={{width: 50}} className="text-center">ลักษณะรถ</th>
                          <th style={{width: 50}} className="text-center">ทะเบียน <br/> ใหม่</th>
                          <th style={{width: 50}} className="text-center">ทะเบียน <br/> เดิม</th>
                          <th className="text-center">วันที่ <br/> ซื้อรถเข้า</th>
                          <th className="text-center"> ซื้อรถจาก </th>
                          <th className="text-center">มัดจำ <br/> เล่มทะเบียน</th>
                          <th className="text-center">วันที่ <br/> รับเล่ม</th>
                          <th className="text-center">สถานะเล่ม</th>
                          <th style={{width: 60}} className="text-center">ไฟแนนซ์เล่ม</th>
                          <th style={{width: 60}} className="text-center">สาขา</th>
                          <th className="text-center">จ.น.วัน <br/> รถจอด(วัน)</th>
                          <th className="text-center">สถานะ</th>
                          <th className={viewed ? "text-center" : "hidden"}>ราคาทุน</th>
                          <th className={viewed ? "text-center" : "hidden"}>ราคาขาย</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableRows}

                        {
                          viewed ? sumRow: null
                        }

                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}

const styles = {
  overflowY: {
    overflow: 'scroll',
  }
}

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
//   token: state.auth.token,
//   isAuthenticated: state.auth.isAuthenticated
// })
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

// export default CarInReport;
export default connect(mapStateToProps)(CarInReport);
