import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import FinanceTaxInvoice from '../FinanceTaxInvoice';
import FinanceTaxCommission from '../FinanceTaxCommission';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { AlertError } from '../../Alert/Alert';

class FinanceTaxTabs extends Component{
  constructor (props) {
    super(props)
    this.state = {
      activeTab: '1',
      car_sell_id: null,
      tax_customer_id: null,
      tax_invoice_status: '',
      tax_commission_status: '',
    };

    this.toggle = this.toggle.bind(this);
    this.loadCarSellById = this.loadCarSellById.bind(this);
    this.onReloadCarSell = this.onReloadCarSell.bind(this);
  }

  componentDidMount () {
    this.mounted = true
    const queryString = require('query-string')
    let car_sell = queryString.parse(this.props.location.search)
    let car_sell_id = car_sell.car_sell_id
    this.setState({
      car_sell_id: car_sell_id
    },() => this.loadCarSellById())
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadCarSellById () {
    const { car_sell_id } = this.state

    axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if (this.mounted) {
          let car_sell = res.data[0]

          this.setState({
            tax_customer_id: car_sell.tax_customer_id,
            tax_invoice_status: car_sell.tax_invoice_status,
            tax_commission_status: car_sell.tax_commission_status
          })

        }
      })

  }

  onReloadCarSell(car_sell, is_load) {
    if (car_sell !== null) {
      // this.loadCarSellById()
      this.setState({
        tax_customer_id: car_sell.tax_customer_id,
        tax_invoice_status: car_sell.tax_invoice_status,
        tax_commission_status: car_sell.tax_commission_status
      })
    } else {
      if (is_load) {
        this.loadCarSellById()
      }
    }
  }


  toggle(tab) {

    if (this.state.tax_customer_id === null && tab==='2') {
      AlertError('กรุณาออกใบกำกับไฟแนนซ์ก่อนทำรายการ')
      return
    }

    if (this.state.tax_invoice_status === 'cancel' && this.state.tax_commission_status === 'nodata' && tab==='2') {
      AlertError('ใบกำกับไฟแนนซ์ถูกยกเลิก ไม่สามารถออกใบกำกับ (ค่าคอมไฟแนนซ์) ได้')
      return
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
    return (
      <div>

        <Nav tabs>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              <i className="icon-printer"/> ใบกำกับไฟแนนซ์
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              <i className="icon-badge"/> ใบกำกับ (ค่าคอมไฟแนนซ์)
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {
              this.state.car_sell_id !== null ? <FinanceTaxInvoice car_sell_id={this.state.car_sell_id} onReloadCarSell={this.onReloadCarSell} /> : 'Loading...'
            }
          </TabPane>

          <TabPane tabId="2">
            {
              this.state.car_sell_id !== null ? <FinanceTaxCommission car_sell_id={this.state.car_sell_id}  /> : 'Loading...'
            }
          </TabPane>

        </TabContent>

      </div>
    )
  }
}

export default FinanceTaxTabs
